import React from "react";
import { ResponsiveRadialBar } from "@nivo/radial-bar";

function getFirstCharacters(sentence) {
  const words = sentence.split(" ");
  const firstCharacters = [];

  for (let i = 0; i < Math.min(3, words.length); i++) {
    const firstChar = words[i].charAt(0);
    firstCharacters.push(firstChar);
  }
  return firstCharacters.join("").toUpperCase();
}

const BestQualificationsRadialBar = ({ qualifications, colors }) => {
  const radialBarData = qualifications?.map((qualification, i) => {
    const id = getFirstCharacters(qualification.name);

    return {
      id,
      data: [
        {
          x: qualification.name,
          y: qualification.number_of_owners,
          color: colors[i],
        },
      ],
    };
  });

  return (
    <ResponsiveRadialBar
      data={radialBarData.reverse()}
      valueFormat=">-.2f"
      padding={0.1}
      cornerRadius={4}
      circularAxisOuter={null}
      colors={qualifications.map((q, i) => colors[i]).reverse()}
      enableRadialGrid={false}
      enableCircularGrid={false}
    />
  );
};

export default BestQualificationsRadialBar;
