import React, { useState, useEffect } from "react";
// import Modal from "react-bootstrap/Modal";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useSelector, useDispatch } from "react-redux";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Slider,
  Select,
  MenuItem,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { addSkill, removeSkill, editSkill } from "../../../../Slices/resume";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ChangingProgressProvider from "../../../../Components/ChangingProgressProvider";
import BorderLinearProgressBar from "../../../../Components/LinearProgress";
import CreateIcon from "@mui/icons-material/Create";
import DeleteIcon from "@mui/icons-material/Delete";
import NewSkill from "./NewSkill";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Skills() {
  const skills = useSelector((state) => state.resume.skills);
  const dispatch = useDispatch();
  // const percentage = 66;

  // const { addSkill, removeSkill } = bindActionCreators(
  //   actionCreators,
  //   dispatch
  // );

  useEffect(() => {
    skills && setSkillsArr(skills)
  },[skills])

  // const [skills, setSkills] = useState([]);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [Alert, setAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  // const [skillType, setSkillType] = React.useState("");
  // console.log(skillType)

  const handleClose = () => {
    setShow(false);
    setShowEdit(false);
    setValidated(false);
  };

  const handleShow = () => setShow(true);
  const handleEdit = () => setShowEdit(true);
  const [isEdit, setIsEdit] = useState(false);
  const [validated, setValidated] = useState(false);

  const [skillsArr, setSkillsArr] = useState(skills);
  const [input, setInput] = useState("");
  const [progressBar, setProgressBar] = useState(0);

  const sumPercentage = (type) => {
    if(skillsArr.length !== 0){
      const values = skillsArr.filter((skill) => skill.type === type && skill);
      const sum = values.map((val) => val.value).reduce((a, b) => a + b);
      const calculation = Math.floor(sum / values.length);
      return calculation;
    }
  };

  const filterByType = (type) => {
    return skillsArr.filter((skill)=> skill.type === type && skill)
  }

  // console.log("SUM PERCENTAGE", sumPercentage("Techniczne"));

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleChangeSkillType = (e) => {
    setSkillsArr((prevState) =>
      prevState.map((skill) =>
        skill.name == e.target.name ? { ...skill, type: e.target.value } : skill
      )
    );
  };

  const handleProgressBar = (e) => {
    setSkillsArr((prevState) =>
      prevState.map((skill) =>
        skill.name == e.target.name
          ? { ...skill, value: e.target.value }
          : skill
      )
    );

    setProgressBar(e.target.value);
  };

  // const handleSkills = (e) => {
  //   e.preventDefault();
  //   const valid = e.currentTarget;
  //   if (!valid.checkValidity()) {
  //     setValidated(true);
  //   } else {
  //     setIsEdit(true);
  //     dispatch(addSkill(input));
  //     setInput("");
  //   }
  // };

  const addToSkills = (skill) => {
      setIsEdit(true);
      dispatch(addSkill(skill));
      setInput("");
  };

  const editSkillsClose = () => {
    // console.log('editSkillsClose')
    dispatch(editSkill(skillsArr));
    handleClose();
  }

  const handleAlertClose = () => setAlert(false);
  const handleAlert = (id) => {
    setDeleteId(id);
    setAlert(true);
  };
  const handleDelete = (id) => {
    let arr = [...skillsArr];
    arr.splice(id, 1);
    setSkillsArr(arr);

    dispatch(removeSkill(id));
    setAlert(false);
  };

  useEffect(() => {
    if (skills.length === 0) {
      setIsEdit(false);
    }
  }, [skills]);

  function removeDuplicates(arr) {
    let s = new Set(arr);
    let it = s.values();
    return Array.from(it);
  }

  const types = removeDuplicates(skillsArr.map((skill) => skill.type));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    backgroundColor: "#f8f9fa",
    paddingTop: "15px",
    paddingBottom: "15px",
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Item>
        <Grid container>
          <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
            <Typography variant="h6">Umiejętności</Typography>
          </Grid>
          <Grid justifyContent="flex-end">
            <Fab
              size="small"
              color="primary"
              aria-label="edit"
              onClick={handleEdit}
              sx={{ mr: 1 }}
            >
              <EditIcon />
            </Fab>
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleShow}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Item>

      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ borderBottom: "1px solid #0000001f", mt: 1, pb: 2 }}
        >
          <Grid container spacing={2} sx={{ justifyContent: "center", mt: 4 }}>
            <Grid item xs={12} lg={10}>
              <Grid container spacing={3}>
                {types.map((type) => (
                      <Grid key={type} item xs={12} sm={6}>
                        <Paper elevation={0} sx={{ p: 6 }}>
                          <Typography>{type}</Typography>
                          <CircularProgressbar
                            value={sumPercentage(type)}
                            text={`${sumPercentage(type)}%`}
                            styles={buildStyles({
                              pathColor: "#d2ab67",
                              trailColor: "#eee",
                              textColor: "black",
                              strokeLinecap: "butt",
                            })}
                            />
                            {filterByType(type).map(({ name, type, value })=>(
                              <Box key={name} sx={{ mt: 4 }}>
                              <Typography>{name}</Typography>
                              <BorderLinearProgressBar value={value} />
                            </Box>
                            ))}
                        </Paper>
                      </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal open={show} onClose={handleClose}>
        <Box sx={style} component="form" noValidate autoComplete="off">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Umiejętności
          </Typography>

          <Grid item xs={4} sx={{ alignSelf: "center" }}>
            <Stack direction="row" spacing={1} sx={{ paddingBottom: "15px" }}>
              {skills.map((items, id) => {
                <Chip
                  label={items.name}
                  onClick={() => {
                    handleAlert(id);
                  }}
                />;
              })}
            </Stack>
          </Grid>
          <NewSkill skillsArr={skillsArr} setSkillsArr={setSkillsArr} addSkill={addToSkills} />
          <Button variant="outlined" onClick={handleClose} sx={{ mt: "10px" }}>
            Zapisz zmiany
          </Button>
        </Box>
      </Modal>

      {/* EDIT MODAL */}
      <Modal open={showEdit} onClose={handleClose}>
        <Box sx={style} component="form" noValidate autoComplete="off">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Umiejętności
          </Typography>

          <Box
            direction="row"
            spacing={1}
            sx={{ paddingBottom: "15px", flexWrap: "wrap" }}
          >
            {skillsArr.map(({ name, value, type }, id) => (
              <div key={name}>
                {/* {console.log(name, value, type)} */}
                <Divider>
                  <Chip label={name} sx={{ mb: 2 }} />
                </Divider>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Select
                    labelId="skill-type"
                    id="skill-type"
                    name={name}
                    // defaultValue={type}
                    value={type}
                    label="Kategoria"
                    onChange={handleChangeSkillType}
                    sx={{ maxHeight: "40px" }}
                  >
                    <MenuItem value={"Techniczne"}>Techniczne</MenuItem>
                    <MenuItem value={"Gastronomiczne"}>Gastronomiczne</MenuItem>
                    <MenuItem value={"Miękkie"}>Miękkie</MenuItem>
                  </Select>
                  <Fab
                    size="small"
                    color="primary"
                    aria-label="edit"
                    onClick={() => handleDelete(id)}
                    sx={{ mr: 1, alignSelf: "center" }}
                  >
                    <DeleteIcon />
                  </Fab>
                </Box>
                <Slider
                  name={name}
                  defaultValue={value}
                  value={value}
                  onChange={(e) => handleProgressBar(e)}
                  aria-label="Default"
                  valueLabelDisplay="auto"
                  sx={{ mt: 3 }}
                />
                {/* <Divider sx={{mb:2}}/> */}
                <Grid justifyContent="flex-end"></Grid>
              </div>
            ))}
          </Box>

          <Button variant="outlined" onClick={editSkillsClose} sx={{ mt: "10px" }}>
            Zapisz zmiany
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default Skills;
















// import React, { useState, useEffect } from "react";
// // import Modal from "react-bootstrap/Modal";
// import { ImCheckmark, ImCross } from "react-icons/im";
// import { useSelector, useDispatch } from "react-redux";

// import Paper from "@mui/material/Paper";
// import { styled } from "@mui/material/styles";
// import Fab from "@mui/material/Fab";
// import AddIcon from "@mui/icons-material/Add";
// import Chip from "@mui/material/Chip";
// import Stack from "@mui/material/Stack";
// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";
// import { Button, Grid, TextField, Typography, IconButton, Slider } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import { addSkill, removeSkill } from "../../../Slices/resume";

// import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// import ChangingProgressProvider from "../../../Components/ChangingProgressProvider";
// import BorderLinearProgressBar from "../../../Components/LinearProgress";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
// };

// function Skills() {
//   const skills = useSelector((state) => state.resume.skills);
//   const dispatch = useDispatch();
//   const percentage = 66;

//   // const { addSkill, removeSkill } = bindActionCreators(
//   //   actionCreators,
//   //   dispatch
//   // );

//   // const [skills, setSkills] = useState([]);
//   const [show, setShow] = useState(false);
//   const [Alert, setAlert] = useState(false);
//   const [deleteId, setDeleteId] = useState(null);

//   const handleClose = () => {
//     setShow(false);
//     setValidated(false);
//   };
//   const handleShow = () => setShow(true);
//   const [isEdit, setIsEdit] = useState(false);
//   const [validated, setValidated] = useState(false);

//   const [input, setInput] = useState("");
//   const handleInput = (e) => {
//     setInput(e.target.value);
//   };

//   const handleSkills = (e) => {
//     e.preventDefault();
//     const valid = e.currentTarget;
//     if (!valid.checkValidity()) {
//       setValidated(true);
//     } else {
//       setIsEdit(true);
//       dispatch(addSkill(input));
//       setInput("");
//     }
//   };

//   const handleAlertClose = () => setAlert(false);
//   const handleAlert = (id) => {
//     setDeleteId(id);
//     setAlert(true);
//   };
//   const handleDelete = (id) => {
//     dispatch(removeSkill(id));
//     setAlert(false);
//   };
//   useEffect(() => {
//     if (skills.length === 0) {
//       setIsEdit(false);
//     }
//   }, [skills]);

//   const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     backgroundColor: "#f8f9fa",
//     paddingTop: "15px",
//     paddingBottom: "15px",
//     textAlign: "left",
//     color: theme.palette.text.secondary,
//   }));

//   return (
//     <>
//       <Item>
//         <Grid container>
//           <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
//             <Typography variant="h6">Umiejętności</Typography>
//           </Grid>
//           <Grid justifyContent="flex-end">
//             <Fab
//               size="small"
//               color="primary"
//               aria-label="add"
//               onClick={handleShow}
//             >
//               <AddIcon />
//             </Fab>
//           </Grid>
//         </Grid>
//       </Item>

//       <Grid container >
//         <Grid
//           item
//           xs={12}
//           sx={{ borderBottom: "1px solid #0000001f", mt: 1, pb: 2 }}
//         >
//           <Box direction="row" spacing={1} sx={{ paddingBottom: "15px", flexWrap: "wrap" }}>
//             {skills.map((items, id) => {
//               return (
//                 <Chip key={id} label={items} size="small" variant="outlined" sx={{ ml: 1, mb: 1 }}/>
//               );
//             })}
//           </Box>
//         </Grid>
//       </Grid>

//       <Grid container spacing={2} sx={{ justifyContent: "center", mt: 4 }}>
//           <Grid item xs={12} lg={8}>
//             <Grid container spacing={3}>
//               {skills.map(()=> (
//                 <Grid item xs={12} sm={6}>
//                   <Paper elevation={0} sx={{ p: 6 }}>
//                     <Typography>IT</Typography>
//                     <CircularProgressbar
//                       value={percentage}
//                       text={`${percentage}%`}
//                       styles={buildStyles({
//                         pathColor: "#d2ab67",
//                         trailColor: "#eee",
//                         textColor: "black",
//                         strokeLinecap: "butt",
//                       })}
//                     />
//                     <Box sx={{ mt: 4 }}>
//                       <Typography>Adobe Photoshop</Typography>
//                       <BorderLinearProgressBar value={40} />
//                     </Box>
//                     <Box sx={{ mt: 4 }}>
//                       <Typography>Projektowanie CAD 2D i 3D</Typography>
//                       <BorderLinearProgressBar value={70} />
//                     </Box>
//                   </Paper>
//                 </Grid>
//               ))}

//               <Grid item xs={12} sm={6}>
//                 <Paper elevation={0} sx={{ p: 6 }}>
//                   <Typography>Techniczne</Typography>
//                   <ChangingProgressProvider values={[0, 20, 40, 60, 80, 100]}>
//                     {(percentage) => (
//                       <CircularProgressbar
//                         value={percentage}
//                         text={`${percentage}%`}
//                         styles={buildStyles({
//                           pathColor: "#d2ab67",
//                           trailColor: "#eee",
//                           textColor: "black",
//                           strokeLinecap: "butt",
//                         })}
//                       />
//                     )}
//                   </ChangingProgressProvider>
//                 </Paper>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>

//       <Modal open={show} onClose={handleClose}>
//         <Box sx={style} component="form" noValidate autoComplete="off">
//         <IconButton
//           aria-label="close"
//           onClick={handleClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//           <Typography variant="h4" sx={{ mb: 2 }}>
//             Umiejętności
//           </Typography>
//           <Box

//             sx={{
//               display: "grid",
//               gridTemplateColumns: { sm: "2fr 1fr" },
//               gap: 1,
//             }}
//           >
//             <TextField
//               fullWidth
//               label="Wpisz umiejętność"
//               id="fullWidth"
//               value={input}
//               placeholder="Napisz coś o sobie"
//               onChange={handleInput}
//             />
//             <Button variant="outlined" onClick={handleSkills}>
//               Dodaj
//             </Button>
//           </Box>
//           <Grid item xs={4} sx={{ alignSelf: "center" }}>
//             <Stack direction="row" spacing={1} sx={{ paddingBottom: "15px" }}>
//               {skills.map((items, id) => {
//                 <Chip
//                   label={items}
//                   onClick={() => {
//                     handleAlert(id);
//                   }}
//                 />
//               })}
//             </Stack>
//           </Grid>

//           <Box
//             direction="row"
//             spacing={1}
//             sx={{ paddingBottom: "15px", flexWrap: "wrap" }}
//           >
//             {skills.map((items, id) => (
//               <>
//               <Chip
//                 label={items}
//                 onClick={() => {
//                   handleAlert(id);
//                 }}
//               />
//               <Slider defaultValue={50} aria-label="Default" valueLabelDisplay="auto" />
//               </>
//             ))}
//           </Box>

//           <Button variant="outlined" onClick={handleClose} sx={{ mt: "10px" }}>
//             Zapisz zmiany
//           </Button>
//         </Box>
//       </Modal>
//     </>
//   );
// }

// export default Skills;
