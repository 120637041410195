import React, { useEffect, useState } from "react";
import { useDocumentTitle } from "../../Hooks/useDocumentTitle";
import { Footer } from "../../Components/Footer";
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { getBadges } from "../../Slices/badges";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import axios from "axios";
import ValidateBadge from "../../Components/validateBadge";
import moment from "moment";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import userService from "../../Actions/services";
import ValidateBadgeFake from "../../Components/validateBadgeFake";

export default function PublicBadge() {
  useDocumentTitle("Odznaka ");
  let { slug } = useParams();
  const [badge, setBadge] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  useEffect(() => {
    userService.getPublicBadge(slug).then((res) => setBadge(res.data));
  }, [slug]);

  //   const selectBadge = createSelector(
  //     (state) => state.badges,
  //     (badges) => badges.filter((badge) => badge.id === Number(badgeId))[0]
  //   );
  //   const badge = useSelector(selectBadge);

  const CutText = (text, maxWords) => {
    const words = text.split(" ");

    if (words.length <= maxWords) {
      return text;
    } else {
      const truncatedWords = words.slice(0, maxWords);
      return truncatedWords.join(" ") + "...";
    }
  };

  return (
    <>
      {badge && (
        <Container>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h4"
                  align="center"
                  color="text.primary"
                  sx={{ marginY: "40px" }}
                >
                  {badge.badgeName}
                </Typography>
              </Grid>

              <Grid container spacing={8}>
                <Grid item xs={12} md={5} sx={{ textAlign: "center" }}>
                  <img
                    // src={`${process.env.REACT_APP_STRAPI_MEDIA}${badge.attributes.image.data.attributes.url}`}
                    src={badge.image.url}
                    alt="badge"
                    style={{ width: "90%", marginTop: "20px" }}
                  />
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    component="h2"
                    variant="h5"
                    sx={{
                      marginBottom: "50px",
                      textAlign: {
                        xs: "center",
                        md: "left",
                      },
                    }}
                  >
                    <span style={{ fontWeight: "bold" }}>
                      {badge.description}
                    </span>
                  </Typography>
                  <Box>
                    <Typography component="h4" variant="subtitle1">
                      Data wystawienia:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {moment(badge.issuanceDate).format("LL")}
                      </span>
                    </Typography>
                    {console.log(badge)}
                    <Typography component="h4" variant="subtitle1">
                      Data egzaminu:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.exam_expiry_date
                          ? moment(badge.exam_expiry_date).format("LL")
                          : ""}
                      </span>
                    </Typography>
                    {/* <Typography component="h4" variant="subtitle1">
                      Instytucja wystawiająca:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.attributes.issuer}
                      </span>
                    </Typography> */}
                    <Typography component="h4" variant="subtitle1">
                      Posiadacz:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {`${badge.user.firstname} ${badge.user.lastname}`}
                      </span>
                    </Typography>
                    <Typography component="h4" variant="subtitle1">
                      Rodzaj odznaki:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.badgeType}
                      </span>
                    </Typography>
                    <Typography component="h4" variant="subtitle1">
                      Kwalifikacja:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.qualificationName}
                      </span>
                    </Typography>
                    <Typography component="h4" variant="subtitle1">
                      Wystawca:{" "}
                      <span style={{ fontWeight: "bold" }}>Micree</span>
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: "40px" }}>
                    <Typography component="h4" variant="subtitle1">
                      EFEKTY UCZENIA SIĘ:
                      <List>
                        {badge.learningEffects?.learningEffects.map((item) => (
                          <ListItem sx={{ padding: 0 }}>
                            <ListItemText primary={item.effectName} />
                          </ListItem>
                        ))}
                      </List>
                    </Typography>
                  </Box>
                  {/* <a href={badge.image.url}>
                    <Button
                      variant="outlined"
                      sx={{
                        mt: 4,
                        padding: "15px 32px",
                        backgroundColor: "#ed6c0217",
                      }}
                    >
                      Pobierz mikropoświadczenie
                    </Button>
                  </a> */}
                  {/* Fake pod prezentacje */}
                  <Button
                    variant="outlined"
                    sx={{
                      mt: 4,
                      ml: 2,
                      padding: "15px 32px",
                      backgroundColor: "#ed6c0217",
                    }}
                    onClick={() => setShow(true)}
                  >
                    Weryfikuj
                  </Button>
                  <Modal
                    open={show}
                    onClose={handleClose}
                    sx={{
                      justifyContent: "center",
                      height: "100vh",
                      alignContent: "center",
                    }}
                  >
                    <ValidateBadgeFake setShow={setShow} />
                  </Modal>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      {/* <Footer /> */}
    </>
  );
}
