import React, { useState, useEffect } from "react";
// import Modal from "react-bootstrap/Modal";
import { ImCheckmark, ImCross } from "react-icons/im";
import { useSelector, useDispatch } from "react-redux";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Slider,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  addBusinessActivity,
  removeBusinessActivity,
} from "../../../Slices/resume";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const businessCompetences = [
  "Umiejętności przywódcze",
  "Planowanie strategiczne",
  "Zarządzanie finansami",
  "Marketing i sprzedaż",
  "Komunikacja i negocjacje",
  "Rozwiązywanie problemów i podejmowanie decyzji",
  "Zarządzanie operacyjne",
  "Zarządzanie zasobami ludzkimi",
  "Zarządzanie projektami",
  "Zarządzanie zmianą",
  "Wiedza prawna i znajomość przepisów (w tym RODO)",
  "Zarządzanie ryzykiem",
  "Umiejętności cyfrowe",
  "Umiejętności analityczne",
  "Kreatywność i innowacyjność",
  "INNE",
];

function BusinessActivities() {
  const businessActivities = useSelector(
    (state) => state.resume.businessActivities
  );
  const dispatch = useDispatch();
  const percentage = 66;

  // const { addSkill, removeSkill } = bindActionCreators(
  //   actionCreators,
  //   dispatch
  // );

  // const [skills, setSkills] = useState([]);
  const [show, setShow] = useState(false);
  const [Alert, setAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [input, setInput] = useState("");
  const [ownInput, setOwnInput] = useState(false);

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setOwnInput(false);
    setInput("");
  };
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleSkills = (e) => {
    e.preventDefault();
    const valid = e.currentTarget;
    if (!valid.checkValidity()) {
      setValidated(true);
    } else {
      setIsEdit(true);
      dispatch(
        addBusinessActivity({
          name: input,
          value: "0",
          type: "kompetencje społeczne",
        })
      );
      setInput("");
    }
    setOwnInput(false);
  };

  const handleChange = (e) => {
    if (e.target.value === "INNE") {
      setOwnInput(true);
      setInput("");
    } else {
      setOwnInput(false);
      setInput(e.target.value);
    }
  };

  const handleAlert = (id) => {
    setDeleteId(id);
    setAlert(true);
  };
  const handleDelete = (id) => {
    dispatch(removeBusinessActivity(id));
    setAlert(false);
  };
  useEffect(() => {
    if (businessActivities?.length === 0) {
      setIsEdit(false);
    }
  }, [businessActivities]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    paddingTop: "15px",
    paddingBottom: "15px",
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Item>
        <Grid container>
          <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
            <Typography variant="h6">Działalność gospodarcza</Typography>
          </Grid>
          <Grid justifyContent="flex-end">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleShow}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Item>

      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ borderBottom: "1px solid #0000001f", mt: 1, pb: 2 }}
        >
          <Box
            direction="row"
            spacing={1}
            sx={{ paddingBottom: "15px", flexWrap: "wrap" }}
          >
            {businessActivities?.map((item, id) => {
              return (
                <Chip
                  key={id}
                  label={item.name}
                  size="small"
                  variant="outlined"
                  sx={{ ml: 1, mb: 1 }}
                />
              );
            })}
          </Box>
        </Grid>
      </Grid>

      <Modal open={show} onClose={handleClose}>
        <Box sx={style} component="form" noValidate autoComplete="off">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Działalność gospodarcza
          </Typography>

          <Grid item xs={12} sx={{ alignSelf: "center" }}>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              flexWrap="wrap"
              sx={{ paddingBottom: "15px", width: "100%" }}
            >
              {businessActivities?.map((item, id) => (
                <Chip
                  key={id}
                  label={item.name}
                  onDelete={() => handleDelete(id)}
                  deleteIcon={<DeleteIcon />}
                  onClick={() => {
                    handleAlert(id);
                  }}
                />
              ))}
            </Stack>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={9} sx={{ my: 1 }}>
                {ownInput ? (
                  <TextField
                    fullWidth
                    label="Wpisz umiejętność"
                    id="fullWidth"
                    value={input}
                    placeholder="Napisz coś o sobie"
                    onChange={handleInput}
                  />
                ) : (
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="courseCategory">
                      Wybierz umiejętność
                    </InputLabel>
                    <Select
                      labelId="courseCategory"
                      id="courseCategory"
                      defaultValue={""}
                      value={input}
                      label="Wybierz umiejętność"
                      onChange={(e) => handleChange(e)}
                      sx={{ width: "100%" }}
                    >
                      <MenuItem value="" key="0">
                        Wybierz umiejętność
                      </MenuItem>
                      {businessCompetences.map((competence, index) => {
                        return (
                          <MenuItem key={index + 1} value={competence}>
                            {competence}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={3}>
                <Button variant="outlined" onClick={handleSkills}>
                  Dodaj
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Button variant="outlined" onClick={handleClose} sx={{ mt: "10px" }}>
            Zapisz zmiany
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default BusinessActivities;
