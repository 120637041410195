import axios from "axios";
const OPEN_BADGE_API_URL = process.env.REACT_APP_OPEN_BADGE_API_URL;
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
// const REACT_APP_X_PERMISSION_KEY = process.env.REACT_APP_X_PERMISSION_KEY;
// const REACT_APP_X_API_KEY = process.env.REACT_APP_X_API_KEY;

const validateBadgeImage = async (file) => {
  const formData = new FormData();
  if (file) {
    formData.append("image", file[0]);
    formData.append(
      "recepient",
      JSON.stringify({
        type: "ID",
        value: "string",
      })
    );

    try {
      const response = await axios.post(
        `${OPEN_BADGE_API_URL}/validator/image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.error("Badge validation error", error);
      throw error;
    }
  } else {
    console.error("Nie dodałeś żadnego pliku!!!");
  }
};

const validateBadgeById = async (id) => {
  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/open-badge-api/validate-badge-by-id`,
      {
        id,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Badge validation error", error);
    throw error;
  }
};

const getPathStats = async () => {
  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/open-badge-api/get-path-stats`
    );

    return response.data;
  } catch (error) {
    console.error("Get issued credentials error", error);
  }
};

const getProfileSubgroups = async (profileId, section) => {
  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/open-badge-api/get-profile-subgroups`,
      {
        profileId,
        section,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Get issued credentials error", error);
  }
};

const getPrkStats = async () => {
  const userId = JSON.parse(localStorage.getItem("user")).user.id;

  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/open-badge-api/get-prk-stats`,
      {
        userId,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Get PRK stats error", error);
  }
};

const getLearningPathStats = async (learningPathId) => {
  const userId = JSON.parse(localStorage.getItem("user")).user.id;
  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/open-badge-api/get-learning-path-stats`,
      {
        learningPathId,
        userId,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Get learning paths stats error", error);
  }
};

const getProfileScores = async ({ profileId }) => {
  const userId = JSON.parse(localStorage.getItem("user")).user.id;
  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/open-badge-api/get-profile-scores`,
      {
        profileId,
        userId,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Get get profile stats error", error);
  }
};

const getMostCommonQualifications = async () => {
  try {
    const response = await axios.post(
      `${REACT_APP_API_URL}/open-badge-api/get-most-common-qualifications`
    );

    return response.data;
  } catch (error) {
    console.error("Get most common qualifications stats error", error);
  }
};

// REQUESTS WITHOUT PROXY SERVER

// const validateBadgeImage = async (file) => {
//   const formData = new FormData();
//   if (file) {
//     formData.append("image", file[0]);
//     formData.append(
//       "recepient",
//       JSON.stringify({
//         type: "ID",
//         value: "string",
//       })
//     );

//     try {
//       const response = await axios.post(
//         `${OPEN_BADGE_API_URL}/validator/image`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       return response.data;
//     } catch (error) {
//       console.error("Badge validation error", error);
//       throw error;
//     }
//   } else {
//     console.error("Nie dodałeś żadnego pliku!!!");
//   }
// };

// const validateBadgeById = async (id) => {
//   const JWT = `Bearer ${JSON.parse(localStorage.getItem("user")).jwt}`;

//   try {
//     const response = await axios.post(`${OPEN_BADGE_API_URL}/validator/url`, {
//       headers: {
//         "Content-Type": "application/json",
//         "x-permission-key": REACT_APP_X_PERMISSION_KEY,
//         "x-api-key": REACT_APP_X_API_KEY,
//         Authorization: JWT,
//       },

//       url: `https://openbadges.dev.euvic.pl/credential/${id}`,
//     });

//     return response.data;
//   } catch (error) {
//     console.error("Badge validation error", error);
//     throw error;
//   }
// };

// const getPathStats = async () => {
//   const JWT = `Bearer ${JSON.parse(localStorage.getItem("user")).jwt}`;

//   try {
//     const response = await axios.get(
//       `${OPEN_BADGE_API_URL}/statistics/pages/general-statistics/issued-credentials/counts`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "x-permission-key": REACT_APP_X_PERMISSION_KEY,
//           "x-api-key": REACT_APP_X_API_KEY,
//           Authorization: JWT,
//         },
//       }
//     );

//     return response.data;
//   } catch (error) {
//     console.error("Get issued credentials error", error);
//   }
// };

// const getProfileSubgroups = async (profileId, section) => {
//   const JWT = `Bearer ${JSON.parse(localStorage.getItem("user")).jwt}`;
//   console.log("profileId, section ", profileId, section);
//   try {
//     const response = await axios.get(
//       `${OPEN_BADGE_API_URL}/statistics/pages/profile-section-statistics/${profileId}/sub-groups?section=${section}`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "x-permission-key": REACT_APP_X_PERMISSION_KEY,
//           "x-api-key": REACT_APP_X_API_KEY,
//           Authorization: JWT,
//         },
//       }
//     );

//     console.log("getProfileSubgroups", response);
//     return response.data;
//   } catch (error) {
//     console.error("Get issued credentials error", error);
//   }
// };

// const getPrkStats = async () => {
//   const JWT = `Bearer ${JSON.parse(localStorage.getItem("user")).jwt}`;
//   const userId = JSON.parse(localStorage.getItem("user")).user.id;

//   try {
//     const response = await axios.get(
//       `${OPEN_BADGE_API_URL}/statistics/pages/general-statistics/average-prk-level/user/${userId}`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "x-permission-key": REACT_APP_X_PERMISSION_KEY,
//           "x-api-key": REACT_APP_X_API_KEY,
//           Authorization: JWT,
//         },
//       }
//     );

//     console.log("getPrkStats", response);
//     return response.data;
//   } catch (error) {
//     console.error("Get PRK stats error", error);
//   }
// };

// const getLearningPathStats = async (learningPathId) => {
//   const JWT = `Bearer ${JSON.parse(localStorage.getItem("user")).jwt}`;
//   const userId = JSON.parse(localStorage.getItem("user")).user.id;
//   try {
//     const response = await axios.get(
//       `${OPEN_BADGE_API_URL}/statistics/pages/learning-path-statistics/${learningPathId.id}?userId=${userId}`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "x-permission-key": REACT_APP_X_PERMISSION_KEY,
//           "x-api-key": REACT_APP_X_API_KEY,
//           Authorization: JWT,
//         },
//       }
//     );

//     console.log("getLearningPathStats", response);
//     return response.data;
//   } catch (error) {
//     console.error("Get learning paths stats error", error);
//   }
// };

// const getProfileScores = async ({ profileId }) => {
//   console.log("profileId", profileId);
//   const JWT = `Bearer ${JSON.parse(localStorage.getItem("user")).jwt}`;
//   const userId = JSON.parse(localStorage.getItem("user")).user.id;
//   try {
//     const response = await axios.get(
//       `${OPEN_BADGE_API_URL}/statistics/pages/profile-statistics/${profileId}/sections?userId=${userId}`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "x-permission-key": REACT_APP_X_PERMISSION_KEY,
//           "x-api-key": REACT_APP_X_API_KEY,
//           Authorization: JWT,
//         },
//       }
//     );

//     console.log("getProfileStats", response);
//     return response.data;
//   } catch (error) {
//     console.error("Get get profile stats error", error);
//   }
// };

// const getMostCommonQualifications = async () => {
//   const JWT = `Bearer ${JSON.parse(localStorage.getItem("user")).jwt}`;
//   try {
//     const response = await axios.get(
//       `${OPEN_BADGE_API_URL}/statistics/pages/general-statistics/most-popular-qualifications`,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           "x-permission-key": REACT_APP_X_PERMISSION_KEY,
//           "x-api-key": REACT_APP_X_API_KEY,
//           Authorization: JWT,
//         },
//       }
//     );

//     console.log("getMostCommonQualifications", response);
//     return response.data;
//   } catch (error) {
//     console.error("Get most common qualifications stats error", error);
//   }
// };

const badgeService = {
  validateBadgeImage,
  getPathStats,
  getPrkStats,
  getLearningPathStats,
  getProfileScores,
  getProfileSubgroups,
  getMostCommonQualifications,
  validateBadgeById,
};
export default badgeService;
