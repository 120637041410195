import { authHeader } from "./authenticationActions";
import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const UPLOADS_URL = process.env.REACT_APP_UPLOADS_URL;

const getPublicContent = () => {
  return axios.get(API_URL + "all");
};

const getUserCertificates = (email) => {
  const qs = require("qs");
  const query = qs.stringify(
    {
      populate: "*",
      filters: {
        user: {
          email: {
            $eq: email,
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return axios.get(`${API_URL}/certificates?${query}`);
};

const getUserQualifications = () => {
  return axios.get(`${API_URL}/qualifications/?populate=*`);
};

const getUserResume = (id, token) => {
  const qs = require("qs");
  const query = qs.stringify(
    {
      fields: ["about"],
      populate: {
        profile: {
          fields: "*",
        },
        itCompetences: {
          fields: "*",
        },
        socialCompetences: {
          fields: "*",
        },
        interests: {
          fields: "*",
        },
        foreignLanguages: {
          fields: "*",
        },
        businessActivities: {
          fields: "*",
        },
        educationList: {
          populate: "files",
        },
        experienceList: {
          populate: "files",
        },
        externalCourses: {
          populate: "files",
        },
      },
    },

    {
      encodeValuesOnly: true,
    }
  );
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios
    .get(`${API_URL}/cvs/${id}?${query}`)
    .catch((err) => console.error("Get user resume error", err));
};

const getResumeAttachedFiles = (id, token) => {
  const qs = require("qs");
  const query = qs.stringify(
    {
      populate: {
        filesEducation: {
          fields: "*",
        },
        filesExperience: {
          fields: "*",
        },
        filesCourses: {
          fields: "*",
        },
      },
    },

    {
      encodeValuesOnly: true,
    }
  );
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios
    .get(`${API_URL}/cvs/${id}?${query}`)
    .catch((err) => console.error("Get resume attached files error", err));
};

const removeResumeAttachedFile = (id, token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios
    .delete(`${API_URL}/upload/files/${id}`)
    .catch((err) => console.error("Get resume attached files error", err));
};

const uploadFile = async (token, user, files, field, setProgress) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const formFile = new FormData();

  const calculatePercent = (value, total) => Math.round((value / total) * 100);

  Array.from(files).forEach((file) =>
    formFile.append(`files`, file, file.name)
  );
  formFile.append("refId", user.id);
  formFile.append("ref", "api::cv.cv");
  formFile.append("field", field);

  try {
    const response = await axios.post(`${UPLOADS_URL}/api/upload`, formFile, {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progress) =>
        setProgress(calculatePercent(progress.loaded, progress.total)),
    });

    return response;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};

const getUserBadges = (email, token) => {
  const qs = require("qs");
  const query = qs.stringify(
    {
      fields: [
        "badgeName",
        "description",
        "issuanceDate",
        "expirationDate",
        "qualificationName",
        "learningEffects",
        "badgeType",
        "slug",
        "exam_expiry_date",
      ],
      populate: {
        image: {
          fields: ["url"],
        },
        badge_model_reference: {
          fields: ["badgeName", "image"],
          populate: {
            image: {
              fields: ["url"],
            },
            sets: {
              fields: ["badgeName", "issuanceDate", "badgeType", "image"],
              populate: {
                image: {
                  fields: ["url"],
                },
              },
            },
          },
        },
        sets: {
          fields: ["badgeName", "issuanceDate", "badgeType", "image"],
          populate: {
            image: {
              fields: ["url"],
            },
          },
        },
      },
      pagination: {
        pageSize: 50,
        page: 1,
      },
      sort: { id: "desc" },
    },
    {
      encodeValuesOnly: true,
    }
  );
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios.get(`${API_URL}/assigned-badges?${query}`);
};

const getCerts = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios.get(`${API_URL}/get-certificates`);
};

const getPublicBadge = (slug) => {
  const qs = require("qs");
  const query = qs.stringify(
    {
      fields: [
        "badgeName",
        "description",
        "issuanceDate",
        "qualificationName",
        "learningEffects",
        "badgeType",
        "exam_expiry_date",
      ],
      populate: {
        image: {
          fields: ["url"],
        },
        user: {
          fields: ["firstName", "lastName"],
        },
        sets: {
          fields: ["badgeName", "issuanceDate", "badgeType", "image"],
          populate: {
            image: {
              fields: ["url"],
            },
          },
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  return axios.get(`${API_URL}/public-badge-url/${slug}?${query}`);
};

const getBadgeModel = ({ id, token }) => {
  const qs = require("qs");
  const query = qs.stringify(
    {
      fields: [
        "badgeName",
        "description",
        "learningEffects",
        "badgeType",
        "badgeName",
      ],
      populate: {
        image: {
          fields: ["url"],
        },
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios.get(`${API_URL}/badge-models/${id}?${query}`);
};

const getCompetencePaths = (token, page) => {
  const qs = require("qs");
  const query = qs.stringify(
    {
      populate: "*",
      pagination: {
        page,
        pageSize: 6,
      },
    },
    {
      encodeValuesOnly: true,
    }
  );
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios
    .get(`${API_URL}/learning-paths/?${query}`)
    .catch((err) => console.error("Get learning path error", err));
};

// COMPETENCE PATHS END

// SINGLE COMPETENCE PATH

const getSinglePathStats = (id, token) => {
  const qs = require("qs");
  const query = qs.stringify(
    {
      fields: ["name", "createdAt"],
      populate: {
        kwalifikacje_bazowe: {
          fields: ["badgeName", "createdAt"],
          populate: {
            image: {
              fields: ["url"],
            },
          },
        },
        kwalifikacje_uzupelniajace: {
          fields: ["badgeName", "createdAt"],
          populate: {
            image: {
              fields: ["url"],
            },
          },
        },
        jezyki_obce: {
          fields: ["badgeName", "createdAt"],
          populate: {
            image: {
              fields: ["url"],
            },
          },
        },
        kompetencje_it: {
          fields: ["badgeName", "createdAt"],
          populate: {
            image: {
              fields: ["url"],
            },
          },
        },
        kompetencje_spoleczne: {
          fields: ["badgeName", "createdAt"],
          populate: {
            image: {
              fields: ["url"],
            },
          },
        },
        dzialalnosc_gospodarcza: {
          fields: ["badgeName", "createdAt"],
          populate: {
            image: {
              fields: ["url"],
            },
          },
        },
      },
    },

    {
      encodeValuesOnly: true,
    }
  );
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios
    .get(`${API_URL}/check-badges-in-path/${id}?${query}`)
    .catch((err) => console.error("Get single path error", err));
};

// SINGLE COMPETENCE PATH END

// PROFILE

const getProfile = (id, token) => {
  const qs = require("qs");
  const query = qs.stringify(
    {
      populate: "*",
    },

    {
      encodeValuesOnly: true,
    }
  );
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios
    .get(`${API_URL}/check-badges-in-profiles/${id}?${query}`)
    .catch((err) => console.error("Get profile error", err));
};

// PROFILE END

const userService = {
  getPublicContent,
  getUserCertificates,
  getUserQualifications,
  getUserResume,
  getUserBadges,
  getPublicBadge,
  getBadgeModel,
  getCerts,
  getCompetencePaths,
  getSinglePathStats,
  getProfile,
  uploadFile,
  getResumeAttachedFiles,
  removeResumeAttachedFile,
};
export default userService;
