import React, { useEffect, useState } from "react";
import { useDocumentTitle } from "../../Hooks/useDocumentTitle";
import { Footer } from "../../Components/Footer";
import {
  Chip,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Badge as BaseBadge, badgeClasses } from "@mui/base/Badge";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBadges } from "../../Slices/badges";
import dayjs from "dayjs";
import styled from "styled-components";

const Badge = styled(BaseBadge)(
  ({ theme, customcolor, invisible }) => `
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-family: 'IBM Plex Sans', sans-serif;
  position: relative;
  display: inline-block;
  line-height: 1;
  & .${badgeClasses.badge} {
    z-index: auto;
    position: absolute;
    top: 30px;
    right: 10px;
    min-width: 22px;
    height: 22px;
    padding: 0 6px;
    color: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    text-align: center;
    border-radius: 12px;
    background: ${customcolor};
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
    display: ${invisible === true ? "none" : "block"}
  }
  `
);

export default function Badges() {
  useDocumentTitle("Odznaki");
  const [selectedCategory, setSelectedCategory] = useState("wszystkie");
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const badges = useSelector((state) => state.badges);

  const filters = [
    { name: "Wszystkie", badgeType: "wszystkie", color: "" },
    { name: "Kwalifikacje pełne", badgeType: "Odznaka złota", color: "" },
    {
      name: "Kwalifikacje cząstkowe",
      badgeType: "Odznaka srebrna",
      color: "",
    },
    { name: "Osiągnięcia własne", badgeType: "Odznaka brązowa", color: "" },
  ];

  useEffect(() => {
    dispatch(getBadges({ email: user.user.email, user: user.jwt }));
  }, [dispatch, user.user.email, user.jwt]);

  const CutText = (text, maxWords) => {
    const words = text.split(" ");

    if (words.length <= maxWords) {
      return text;
    } else {
      const truncatedWords = words.slice(0, maxWords);
      return truncatedWords.join(" ") + "...";
    }
  };

  const filteredBadges =
    selectedCategory === "wszystkie"
      ? badges
      : badges.filter(
          (badge) => badge.attributes.badgeType === selectedCategory
        );

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography
              component="h1"
              variant="h4"
              align="center"
              color="text.primary"
              sx={{
                marginTop: { xs: "80px", md: "40px" },
                marginBottom: { xs: "20px", md: "40px" },
              }}
            >
              Portfel Talentów
            </Typography>

            {/* DO NAGRAŃ */}

            {/* <Typography
              sx={{
                position: "absolute",
                right: "30px",
                top: "86px",
                fontWeight: "bold",
              }}
            >
              Jausz Kowalski (Rodzic) - przegląda profil Karol Kowalski
            </Typography> */}
            {/* <Typography
              sx={{
                position: "absolute",
                right: "30px",
                top: "86px",
                fontWeight: "bold",
              }}
            >
              Profil Karol Kowalski
            </Typography> */}
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "30px" }}>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                listStyle: "none",
                p: 0.5,
                m: 0,
              }}
              component="ul"
            >
              {filters.map(({ name, variant, badgeType }) => (
                <Chip
                  key={name}
                  label={name}
                  variant={variant}
                  onClick={() => setSelectedCategory(badgeType)}
                />
              ))}
            </Stack>
          </Grid>
          <Container>
            <Grid item xs={12}>
              {badges.length > 0 ? (
                <Grid container spacing={0} sx={{ alignItems: "flex-start" }}>
                  {filteredBadges.map((badge) => (
                    <Grid
                      key={badge.id}
                      id={badge.id}
                      item
                      xs={12}
                      md={4}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginBottom: "90px",
                      }}
                    >
                      <Link
                        to={`${badge.id}`}
                        style={{ textDecoration: "none", textAlign: "center" }}
                      >
                        <Typography
                          component="h3"
                          variant="h7"
                          align="center"
                          color="text.primary"
                          sx={{
                            height: "50px",
                            margin: "0px 27px",
                            lineHeight: "21px",
                          }}
                        >
                          {CutText(badge.attributes.badgeName, 6)}
                        </Typography>
                        <Badge
                          customcolor="#ff6969"
                          badgeContent="NOWE"
                          invisible={
                            dayjs(badge.attributes.issuanceDate).isBefore(
                              dayjs().subtract(4, "day"),
                              "day"
                            ) && true
                          }
                        >
                          <img
                            src={badge.attributes.image.data?.attributes.url}
                            alt="badge"
                            style={{
                              width: "200px",
                              marginTop: "20px",
                            }}
                          />
                        </Badge>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Grid container spacing={4}>
                  {[0, 0, 0, 0, 0, 0].map((el, i) => (
                    <Grid
                      item
                      xs={4}
                      key={i}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginBottom: "90px",
                      }}
                    >
                      <Skeleton
                        variant="rounded"
                        width={384}
                        height={56}
                        id={i}
                      />
                      <Skeleton
                        variant="circle"
                        width={200}
                        height={220}
                        sx={{ mt: 3 }}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Container>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}
