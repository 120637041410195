import React, { useEffect, useState } from "react";
import { useDocumentTitle } from "../../Hooks/useDocumentTitle";
import { Footer } from "../../Components/Footer";
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Paper,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { getBadges } from "../../Slices/badges";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import axios from "axios";
import ValidateBadge from "../../Components/validateBadge";
import moment from "moment";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
} from "react-share";
import FacebookIcon from "@mui/icons-material/Facebook";
import EmailIcon from "@mui/icons-material/Email";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import LinkZoneWithCopyButton from "../../Components/CopyLinkButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import AnalyzeWithAi from "../../Components/AnalyzeWithAi";

export default function SingleBadge() {
  useDocumentTitle("Odznaka ");
  let { badgeId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  // const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch(getBadges({ email: user.user.email, user: user.jwt }));
  }, [dispatch, user.user.email, user.jwt]);

  const selectBadge = createSelector(
    (state) => state.badges,
    (badges) => badges.filter((badge) => badge.id === Number(badgeId))[0]
  );
  const badge = useSelector(selectBadge);

  const CutText = (text, maxWords) => {
    const words = text.split(" ");

    if (words.length <= maxWords) {
      return text;
    } else {
      const truncatedWords = words.slice(0, maxWords);
      return truncatedWords.join(" ") + "...";
    }
  };

  // const downloadMicrocredential = (url) => {
  //   const imageURL = url;
  //   console.log("url", url);
  //   axios
  //     .get(imageURL)
  //     .then((res) => console.log(res))
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       const url = window.URL.createObjectURL(new Blob([blob]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", "odznaka_vcc.jpg");
  //       document.body.appendChild(link);
  //       link.click();
  //       link.parentNode.removeChild(link);
  //     })
  //     .catch((error) => console.error("Error downloading image:", error));
  // };

  // const verifyMicrocredential = () => {};

  return (
    <>
      {badge && (
        <Container>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h4"
                  align="center"
                  color="text.primary"
                  sx={{ marginY: "40px" }}
                >
                  {badge.attributes.badgeName}
                </Typography>
              </Grid>

              <Grid container spacing={8}>
                <Grid item xs={12} md={5} sx={{ textAlign: "center" }}>
                  <img
                    // src={`${process.env.REACT_APP_STRAPI_MEDIA}${badge.attributes.image.data.attributes.url}`}
                    src={badge.attributes.image.data?.attributes.url}
                    alt="badge"
                    style={{ width: "90%", marginTop: "20px" }}
                  />

                  <Grid container sx={{ marginTop: "50px" }}>
                    {badge.attributes.badge_model_reference.data?.attributes.sets.data.map(
                      (set, i) => (
                        <Grid
                          key={i}
                          item
                          xs={6}
                          sx={{ textAlign: "center", mb: 3 }}
                        >
                          <Typography
                            component="h4"
                            variant="caption"
                            align="center"
                            color="text.primary"
                            minHeight={39}
                          >
                            {CutText(set.attributes.badgeName, 7)}
                          </Typography>
                          <img
                            // src={`${process.env.REACT_APP_MEDIA_URL}${set.attributes.image?.data.attributes.url}`}
                            src={set.attributes.image.data?.attributes.url}
                            alt="badge"
                            style={{ width: "60%", marginTop: "20px" }}
                          />
                        </Grid>
                      )
                    )}
                  </Grid>
                  {/* <Grid container sx={{ marginTop: "50px" }}>
                    {badge.attributes.sets.data.map((set) => (
                      <Grid item xs={6} sx={{ textAlign: "center" }}>
                        <Typography
                          component="h4"
                          variant="caption"
                          align="center"
                          color="text.primary"
                        >
                          {set.attributes.badgeName}
                        </Typography>
                        <img
                          // src={`${process.env.REACT_APP_MEDIA_URL}${set.attributes.image?.data.attributes.url}`}
                          src={set.attributes.image.data.attributes.url}
                          alt="badge"
                          style={{ width: "60%", marginTop: "20px" }}
                        />
                      </Grid>
                    ))}
                  </Grid> */}
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    component="h2"
                    variant="h5"
                    sx={{ marginBottom: "50px" }}
                  >
                    <span style={{ fontWeight: "bold" }}>{badge.desc}</span>
                  </Typography>
                  <Box>
                    <Typography component="h4" variant="subtitle1">
                      Data wystawienia:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {moment(badge.attributes.issuanceDate).format("LL")}
                      </span>
                    </Typography>
                    <Typography component="h4" variant="subtitle1">
                      Data egzaminu:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.attributes.exam_expiry_date
                          ? moment(badge.attributes.exam_expiry_date).format(
                              "LL"
                            )
                          : ""}
                      </span>
                    </Typography>
                    <Typography component="h4" variant="subtitle1">
                      Data wygaśnięcia:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.attributes.expirationDate
                          ? moment(badge.attributes.expirationDate).format("LL")
                          : "bezterminowo"}
                      </span>
                    </Typography>
                    <Typography component="h4" variant="subtitle1">
                      Instytucja wystawiająca: {<b>Micree</b>}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.attributes.issuer}
                      </span>
                    </Typography>
                    <Typography component="h4" variant="subtitle1">
                      Rodzaj odznaki:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.attributes.badgeType}
                      </span>
                    </Typography>
                    <Typography component="h4" variant="subtitle1">
                      Kwalifikacja:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.attributes.qualificationName}
                      </span>
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: "40px" }}>
                    <Typography component="h4" variant="subtitle1">
                      EFEKTY UCZENIA SIĘ:
                      <List>
                        {badge.attributes.learningEffects?.learningEffects.map(
                          (item, i) => (
                            <ListItem key={i} sx={{ padding: 0 }}>
                              <ListItemIcon>
                                <ChevronRightIcon />
                              </ListItemIcon>
                              <ListItemText primary={item.effectName} />
                            </ListItem>
                          )
                        )}
                      </List>
                    </Typography>
                  </Box>

                  <LinkZoneWithCopyButton slug={badge.attributes.slug} />

                  <a href={badge.attributes.image.data?.attributes.url}>
                    <Button
                      variant="outlined"
                      sx={{
                        mt: 4,
                        padding: "15px 32px",
                        backgroundColor: "#ed6c0217",
                      }}
                      // onClick={() =>
                      //   downloadMicrocredential(
                      //     badge.attributes.image.data?.attributes.url
                      //   )
                      // }
                    >
                      Pobierz mikropoświadczenie
                    </Button>
                  </a>
                  {/* <AnalyzeWithAi
                    text={badge.attributes.learningEffects?.learningEffects}
                  /> */}
                  <Button
                    variant="outlined"
                    sx={{
                      mt: 4,
                      ml: 2,
                      padding: "15px 32px",
                      backgroundColor: "#ed6c0217",
                    }}
                    onClick={() => setShow(true)}
                  >
                    Weryfikuj
                  </Button>
                  <Modal
                    open={show}
                    onClose={handleClose}
                    sx={{
                      justifyContent: "center",
                      height: "100vh",
                      alignContent: "center",
                    }}
                  >
                    <ValidateBadge setShow={setShow} />
                  </Modal>
                  <Box sx={{ mt: 4 }}>
                    <FacebookShareButton
                      hashtag={"#Micree-open-badge"}
                      url={`${process.env.REACT_APP_MEDIA_URL}/public-badge/${badge.attributes.slug}`}
                      // url={`https://apka.vccsystem.eu/public-badge/${badge.attributes.slug}`}
                      style={{ padding: "14px" }}
                    >
                      <FacebookIcon
                        fontSize="large"
                        sx={{ color: "#0965fe" }}
                      />
                    </FacebookShareButton>
                    <TwitterShareButton
                      hashtag={"#Micree"}
                      url={"https://micree.pl/"}
                      style={{ padding: "14px" }}
                    >
                      <XIcon fontSize="large" sx={{ color: "#000000" }} />
                    </TwitterShareButton>
                    <LinkedinShareButton
                      hashtag={"#Micree"}
                      style={{ padding: "14px" }}
                      url={"https://micree.pl/"}
                    >
                      <LinkedInIcon
                        fontSize="large"
                        sx={{ color: "#0277b5" }}
                        title={"Badge Micree"}
                        summary={"opis badga"}
                        source={"url z badgem"}
                      />
                    </LinkedinShareButton>
                    <EmailShareButton
                      hashtag={"#Micree"}
                      style={{ padding: "14px" }}
                    >
                      <EmailIcon fontSize="large" sx={{ color: "#7f7f7f" }} />
                    </EmailShareButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <Footer />
    </>
  );
}
