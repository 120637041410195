import React from "react";
import { Grid, Skeleton, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const LearningPaths = ({ competencePahts }) => {
  return (
    <>
      <Typography
        component="h4"
        variant="h7"
        sx={{
          textAlign: "center",
          lineHeight: "40px",
          color: "#d2ab67",
        }}
      >
        Odblokowane ścieżki nauczania:
      </Typography>
      <Grid container spacing={0}>
        {competencePahts.length > 0 ? (
          competencePahts.map((learningPath) => (
            <Grid key={learningPath.id} item xs={12} md={4} lg={4}>
              <Link
                to={`/sciezki/${learningPath.id}`}
                style={{ textDecoration: "none" }}
              >
                <Typography
                  component="h3"
                  variant="h6"
                  align="center"
                  color="text.primary"
                >
                  {learningPath.attributes.name}
                </Typography>
                <img
                  src={learningPath.attributes.image.data?.attributes.url}
                  alt=""
                  width="144px"
                />
              </Link>
            </Grid>
          ))
        ) : (
          <Grid container spacing={4} sx={{ mb: 3 }}>
            {[0, 0, 0, 0, 0, 0].map((el, i) => (
              <Grid
                item
                xs={4}
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Skeleton variant="rounded" width={200} height={32} id={i} />
                <Skeleton
                  variant="circle"
                  width={145}
                  height={150}
                  sx={{ mt: 3 }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default LearningPaths;
