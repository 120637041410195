import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../Actions/services";
import badgeService from "../Actions/openBadgeServices";
import { setMessage } from "./message";
const user = JSON.parse(localStorage.getItem("user"));
const badge = JSON.parse(sessionStorage.getItem("badges"));

export const getSinglePathStats = createAsyncThunk(
  "singlePathStats",
  async ({ id, token }, thunkAPI) => {
    try {
      const data = await userService.getSinglePathStats(id, token);
      return { pathStats: data.data };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getSinglePathQualifications = createAsyncThunk(
  "singlePathQualifications",
  async (thunkAPI) => {
    try {
      const data = await userService.getMostCommonQualifications();
      return { mostCommonQualifications: data };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getLearningPathStats = createAsyncThunk(
  "getLearningPathStats",
  async (id, thunkAPI) => {
    try {
      const data = await badgeService.getLearningPathStats(id);
      return { learningPathStats: data };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {};

const competencePathSlice = createSlice({
  name: "competencePath",
  initialState,
  extraReducers: {
    [getSinglePathStats.pending]: (state, action) => {
      return state;
    },
    [getSinglePathStats.fulfilled]: (state, action) => {
      return (state = { ...state, ...action.payload });
    },
    [getSinglePathStats.rejected]: (state, action) => {
      return (state = []);
    },
    [getSinglePathQualifications.pending]: (state, action) => {
      return state;
    },
    [getSinglePathQualifications.fulfilled]: (state, action) => {
      return (state = { ...state, ...action.payload });
    },
    [getSinglePathQualifications.rejected]: (state, action) => {
      return (state = []);
    },
    [getLearningPathStats.pending]: (state, action) => {
      return state;
    },
    [getLearningPathStats.fulfilled]: (state, action) => {
      return (state = { ...state, ...action.payload });
    },
    [getLearningPathStats.rejected]: (state, action) => {
      return (state = []);
    },
  },
});
const { reducer } = competencePathSlice;
export default reducer;
