import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../Actions/services";
import { setMessage } from "./message";
const user = JSON.parse(localStorage.getItem("user"));
const cert = JSON.parse(sessionStorage.getItem("certificates"));

// export const getCertificates = createAsyncThunk(
//   "certificates",
//   async (email, thunkAPI) => {
//       // console.log(email)
//     try {
//       if(email) {
//         const data = await userService.getUserCertificates(email);
//         return { certificates: data };
//       }
//     } catch (error) {
//     //   const message =
//     //     (error.response &&
//     //       error.response.data &&
//     //       error.response.data.message) ||
//     //     error.message ||
//     //     error.toString();
//     //     console.log(message)
//     //   thunkAPI.dispatch(setMessage(message));
//       return thunkAPI.rejectWithValue();
//     }
//   }
// );

export const getCertificates = createAsyncThunk(
  "certs",
  async ({ user }, thunkAPI) => {
    try {
      if (user) {
        const data = await userService.getCerts(user);

        return data.data;
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = cert ? cert : [];

const getCertificatesSlice = createSlice({
  name: "certificates",
  initialState,
  extraReducers: {
    // [getCertificates.pending]: (state, action) => {
    //   return state;
    // },
    // [getCertificates.fulfilled]: (state, action) => {
    //   return state = action.payload.certificates.data.data;
    // },
    // [getCertificates.rejected]: (state, action) => {
    //     return state = [];
    // },
    [getCertificates.pending]: (state, action) => {
      return state;
    },
    [getCertificates.fulfilled]: (state, action) => {
      return (state = action.payload);
    },
    [getCertificates.rejected]: (state, action) => {
      return (state = []);
    },
  },
});
const { reducer } = getCertificatesSlice;
export default reducer;
