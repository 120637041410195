import React, { useEffect } from "react";
import { withRouter } from "../Hooks/withRouter";
import { useLocation, useNavigate } from "react-router-dom";
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};
const AuthVerify = (props) => {
  const history = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const decodedJwt = parseJwt(user.jwt);
      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.clear();
        navigate("/logout", { replace: true });
      }
    }
  }, [history]);

  return <>{props.children}</>;
};
export default withRouter(AuthVerify);
