// import React from "react";

// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
// import { useSelector } from "react-redux";

// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
// import Stack from "@mui/material/Stack";
// import Avatar from "@mui/material/Avatar";
// import Divider from "@mui/material/Divider";
// import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
// import PlaceIcon from "@mui/icons-material/Place";
// import { Button, Chip, Typography, useMediaQuery } from "@mui/material";
// import SchoolIcon from "@mui/icons-material/School";

// function PdfComponent() {
//   const profile = useSelector((state) => state.resume.profile);
//   const name = profile.name.split(" ");
//   const image = useSelector((state) => state.resume.file);
//   const about = useSelector((state) => state.resume.about);
//   const experienceList = useSelector((state) => state.resume.experienceList);
//   const educationList = useSelector((state) => state.resume.educationList);
//   const skills = useSelector((state) => state.resume.skills);
//   const mobileView = useMediaQuery('(max-width:425px)');

//   const printDocument = () => {
//     const input = document.getElementById("divToPrint");
//     html2canvas(input).then((canvas) => {
//       const imgData = canvas.toDataURL("image/png");
//       const pdf = new jsPDF("p", "pt", "a4", false);
//       pdf.addImage(imgData, "PNG", 0, 0, 600, 0, undefined, false);
//       // pdf.output('dataurlnewwindow');
//       pdf.save("download.pdf");
//     });
//   };

//   return (
//     <>
//       <Grid item xs={12} md={12} sx={mobileView ? { textAlign: "center", mt: 14 } : { textAlign: "center", mt: 5 }}>
//         <Button variant="outlined" onClick={printDocument}>
//           Pobierz PDF
//         </Button>
//       </Grid>

//       <Paper
//         elevation={3}
//         id="divToPrint"
//         sx={
//           mobileView
//             ? {
//                 my: 6,
//                 textAlign: "center",
//                 transform: "scale(0.4) translate(11%, 15%)",
//                 transformOrigin: "0 0",
//                 width: "21cm",
//                 minHeight: "29.7cm",
//                 padding: "2cm",
//                 margin: "1cm auto",
//                 border: "1px #D3D3D3 solid",
//                 borderRadius: "5px",
//                 background: "white",
//                 marginBottom: '-545px',
//               }
//             : {
//                 width: "21cm",
//                 minHeight: "29.7cm",
//                 padding: "2cm",
//                 margin: "1cm auto",
//                 border: "1px #D3D3D3 solid",
//                 borderRadius: "5px",
//                 background: "white",
//               }
//         }
//       >
//         <Grid container spacing={2}>
//           <Grid item xs={6} md={4}>
//             <Avatar
//               alt="Avatar"
//               src={image ? image : "/static/images/avatar/1.jpg"}
//               sx={{ width: 200, height: 200 }}
//             />
//           </Grid>
//           <Grid item xs={6} md={8}>
//             <Stack spacing={2}>
//               <Typography variant="h4" component="h4">
//                 {name[0]}
//               </Typography>
//               <Typography variant="h4" component="h4">
//                 {name[1]}
//               </Typography>
//               <Divider />
//               <Stack
//                 direction="row"
//                 divider={<Divider orientation="vertical" flexItem />}
//                 spacing={2}
//               >
//                 <Grid container spacing={2} sx={{ textAlign: "center" }}>
//                   <Grid
//                     item
//                     xs={6}
//                     sx={{
//                       display: "-webkit-inline-box",
//                       "-webkit-box-pack": "center",
//                     }}
//                   >
//                     <WorkOutlineIcon />
//                     <Typography variant="h7" component="h4">
//                       Twoje stanowisko
//                     </Typography>
//                   </Grid>
//                   <Grid
//                     item
//                     xs={6}
//                     sx={{
//                       display: "-webkit-inline-box",
//                       "-webkit-box-pack": "center",
//                     }}
//                   >
//                     <PlaceIcon />
//                     <Typography variant="h7" component="h4">
//                       Miasto, Kraj
//                     </Typography>
//                   </Grid>
//                 </Grid>
//               </Stack>
//             </Stack>
//           </Grid>
//           <Grid item xs={12} md={12}>
//             <Stack spacing={2}>
//               <Box>
//                 <Typography
//                   variant="h5"
//                   component="h4"
//                   sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
//                 >
//                   O Mnie
//                 </Typography>
//                 <Typography
//                   variant="h7"
//                   component="h4"
//                   sx={{ textAlign: "center" }}
//                 >
//                   {about}
//                 </Typography>
//               </Box>
//               <Box>
//                 <Typography
//                   variant="h5"
//                   component="h4"
//                   sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
//                 >
//                   Umiejętności
//                 </Typography>
//                 <Grid container>
//                   <Grid
//                     sx={{
//                       alignSelf: "center",
//                       justifyContent: "center",
//                       flexGrow: 1,
//                       display: "flex",
//                       mt: 2,
//                     }}
//                   >
//                     <Stack
//                           direction="row"
//                           spacing={1}
//                           sx={{ paddingBottom: "15px", flexWrap: "wrap", justifyContent: 'center' }}
//                         >
//                     {skills.map((items, id) => {
//                       return (

//                           <Chip key={id} label={items} sx={{ mr: 1, mb: 1 }} />

//                       );
//                     })}
//                     </Stack>
//                   </Grid>
//                 </Grid>
//               </Box>
//               <Box>
//                 <Typography
//                   variant="h5"
//                   component="h4"
//                   sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
//                 >
//                   Doświadczenie
//                 </Typography>
//                 <Grid container >
//                   <Grid item sm={12} sx={{ alignSelf: "center", flexGrow: 1 }}>
//                     {experienceList.map((item, id) => {
//                       return (
//                         <Paper
//                           elevation={0}
//                           key={id}
//                           sx={{
//                             p: 2,
//                             bgcolor: "background.default",
//                             borderBottom: "1px solid #555",
//                             borderRadius: 0,
//                           }}
//                         >
//                           <Grid container spacing={2}>
//                             <Grid item sm={2} sx={{ alignSelf: "center" }}>
//                               <WorkOutlineIcon
//                                 fontSize="large"
//                                 sx={{ color: "#d2ab67" }}
//                               />
//                             </Grid>
//                             <Grid item xs={6} sx={{ alignSelf: "center" }}>
//                               <Typography variant="h6">{item.title}</Typography>
//                               <Typography
//                                 variant="h7"
//                                 display="block"
//                                 gutterBottom
//                                 sx={{ mb: 0 }}
//                               >
//                                 {item.company} • {item.startMonth}{" "}
//                                 {item.startYear}
//                                 {`${
//                                   item.isWorking
//                                     ? " - Present"
//                                     : " - " + item.endMonth + " " + item.endYear
//                                 }`}
//                               </Typography>
//                               <Typography
//                                 variant="caption"
//                                 display="block"
//                                 gutterBottom
//                                 sx={{ mb: 0 }}
//                               >
//                                 {item.location}
//                               </Typography>
//                               <Typography
//                                 variant="caption"
//                                 display="block"
//                                 gutterBottom
//                                 sx={{ mb: 0 }}
//                               >
//                                 {item.description}
//                               </Typography>
//                             </Grid>
//                           </Grid>
//                         </Paper>
//                       );
//                     })}
//                   </Grid>
//                 </Grid>
//               </Box>
//               <Box>
//                 <Typography
//                   variant="h5"
//                   component="h4"
//                   sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
//                 >
//                   Edukacja
//                 </Typography>
//                 <Grid container sm={12}>
//                   <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
//                     {educationList.map((item, id) => {
//                       return (
//                         <Paper
//                           elevation={0}
//                           key={id}
//                           sx={{
//                             p: 2,
//                             bgcolor: "background.default",
//                             borderBottom: "1px solid #555",
//                             borderRadius: 0,
//                           }}
//                         >
//                           <Grid container spacing={2}>
//                             <Grid item sm={2} sx={{ alignSelf: "center" }}>
//                               <SchoolIcon
//                                 fontSize="large"
//                                 sx={{ color: "#d2ab67" }}
//                               />
//                             </Grid>
//                             <Grid item xs={6} sx={{ alignSelf: "center" }}>
//                               <Typography variant="h7">
//                                 {item.institute}
//                               </Typography>
//                               <Typography variant="caption">
//                                 {item.degree} • {item.fieldOfStudy}
//                               </Typography>
//                               <Typography
//                                 variant="caption"
//                                 display="block"
//                                 gutterBottom
//                                 sx={{ mb: 0 }}
//                               >
//                                 {item.startYear} - {item.endYear} • Ocena:{" "}
//                                 {item.grade}
//                               </Typography>
//                             </Grid>
//                             <Grid
//                               item
//                               sm={4}
//                               sx={{
//                                 display: "flex",
//                                 flexFlow: "column-reverse",
//                                 alignItems: "end",
//                                 alignSelf: "center",
//                               }}
//                             ></Grid>
//                           </Grid>
//                         </Paper>
//                       );
//                     })}
//                   </Grid>
//                 </Grid>
//               </Box>
//             </Stack>
//           </Grid>
//         </Grid>
//       </Paper>
//     </>
//   );
// }

// export default PdfComponent;

import React from "react";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PlaceIcon from "@mui/icons-material/Place";
import {
  Button,
  Chip,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import TranslateIcon from "@mui/icons-material/Translate";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import BorderLinearProgressBar from "../../../Components/LinearProgress";
import dayjs from "dayjs";

function PdfComponent() {
  const profile = useSelector((state) => state.resume.profile);
  const name = profile.name.split(" ");
  // const image = useSelector((state) => state.resume.file);
  const image = useSelector((state) => state.auth.user.user.profileImage);
  const about = useSelector((state) => state.resume.about);
  const experienceList = useSelector((state) => state.resume.experienceList);
  const educationList = useSelector((state) => state.resume.educationList);
  const externalCourses = useSelector((state) => state.resume.externalCourses);
  const foreignLanguages = useSelector(
    (state) => state.resume.foreignLanguages
  );
  const socialCompetences = useSelector(
    (state) => state.resume.socialCompetences
  );
  const itCompetences = useSelector((state) => state.resume.itCompetences);
  const businessActivities = useSelector(
    (state) => state.resume.businessActivities
  );
  const interests = useSelector((state) => state.resume.interests);
  // const skills = useSelector((state) => state.resume.skills);
  const mobileView = useMediaQuery("(max-width:425px)");

  // const printDocument = () => {
  //   const input = document.getElementById("divToPrint");

  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF("p", "pt", "a4", false);
  //     pdf.addImage(imgData, "PNG", 0, 0, 600, 0, undefined, false);
  //     pdf.addPage();
  //     // pdf.output('dataurlnewwindow');

  //     pdf.save("download.pdf");
  //   });
  // };
  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    const { clientWidth, clientHeight } = input;
    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210;
      const pageHeight = 290;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const doc = new jsPDF("pt", "mm");
      let position = 0;
      doc.addImage(
        imgData,
        "PNG",
        0,
        0,
        imgWidth,
        Math.floor(210 / (clientWidth / clientHeight))
      );
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(
          imgData,
          "PNG",
          0,
          position,
          imgWidth,
          Math.floor(210 / (clientWidth / clientHeight))
        );
        heightLeft -= pageHeight;
      }
      doc.save("download.pdf");
    });
  };

  // const printDocument = () => {
  //   const input = document.getElementById('divToPrint');
  //   html2canvas(input).then((canvas) => {
  //     console.log(canvas)

  //     const imgData = canvas.toDataURL("image/png");
  //     const imgWidth = 794;
  //     const pageHeight = 1349;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     let heightLeft = imgHeight;
  //     let position = 0;

  //     heightLeft -= pageHeight;
  //     const pdf = new jsPDF("p", "pt", "a4", false);
  //       pdf.addImage(imgData, "PNG", 0, 0, 600, 0, undefined, false);
  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       console.log(position, input)
  //       pdf.addPage();
  //       // pdf.addImage(canvas, 'PNG', 0, position, 600, 0, undefined, false);
  //       pdf.addImage(canvas, 'PNG', 0, -830, 600, 0, undefined, false);
  //       heightLeft -= pageHeight;
  //     }
  //     pdf.save('download.pdf');
  //   });
  // }

  // const sumPercentage = (type) => {
  //   if(skills.length !== 0){
  //     const values = skills.filter((skill) => skill.type === type && skill);
  //     const sum = values.map((val) => val.value).reduce((a, b) => a + b);
  //     const calculation = Math.floor(sum / values.length);
  //     return calculation;
  //   }
  // };

  // function removeDuplicates(arr) {
  //   let s = new Set(arr);
  //   let it = s.values();
  //   return Array.from(it);
  // }

  // const types = removeDuplicates(skills.map((skill) => skill.type));

  return (
    <>
      <Grid
        item
        xs={12}
        md={12}
        sx={
          mobileView
            ? { textAlign: "center", mt: 14 }
            : { textAlign: "center", mt: 5 }
        }
      >
        <Button variant="outlined" onClick={printDocument}>
          Pobierz PDF
        </Button>
      </Grid>

      <Paper
        elevation={3}
        id="divToPrint"
        sx={
          mobileView
            ? {
                my: 6,
                textAlign: "center",
                transform: "scale(0.4) translate(11%, 15%)",
                transformOrigin: "0 0",
                width: "21cm",
                minHeight: "29.7cm",
                padding: "2cm",
                margin: "1cm auto",
                // border: "1px #D3D3D3 solid",
                borderRadius: "5px",
                background: "white",
                marginBottom: "-545px",
              }
            : {
                width: "21cm",
                minHeight: "29.7cm",
                padding: "2cm",
                margin: "1cm auto",
                // border: "1px #D3D3D3 solid",
                borderRadius: "5px",
                background: "white",
              }
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <Avatar
              alt="Avatar"
              src={image ? image : "/static/images/avatar/1.jpg"}
              sx={{ width: 200, height: 200 }}
            />
          </Grid>
          <Grid item xs={6} md={8}>
            <Stack spacing={2}>
              <Typography variant="h4" component="h4">
                {name[0]}
              </Typography>
              <Typography variant="h4" component="h4">
                {name[1]}
              </Typography>
              <Divider />
              <Stack
                direction="row"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={2}
              >
                <Grid container spacing={2} sx={{ textAlign: "center" }}>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "-webkit-inline-box",
                      "-webkit-box-pack": "center",
                    }}
                  >
                    <WorkOutlineIcon />
                    <Typography variant="h7" component="h4">
                      Twoje stanowisko
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "-webkit-inline-box",
                      "-webkit-box-pack": "center",
                    }}
                  >
                    <PlaceIcon />
                    <Typography variant="h7" component="h4">
                      Miasto, Kraj
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack spacing={2}>
              <Box>
                <Typography
                  variant="h5"
                  component="h4"
                  sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
                >
                  O Mnie
                </Typography>
                <Typography
                  variant="h7"
                  component="h4"
                  sx={{ textAlign: "center" }}
                >
                  {about}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  component="h4"
                  sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
                >
                  Umiejętności
                </Typography>
                <Grid container>
                  <Grid
                    sx={{
                      alignSelf: "center",
                      justifyContent: "center",
                      flexGrow: 1,
                      display: "flex",
                      mt: 2,
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{
                        paddingBottom: "15px",
                        flexWrap: "wrap",
                        justifyContent: "center",
                      }}
                    >
                      {/* {skills.map((items, id) => {
                      return (
                        
                          <Chip key={id} label={items} sx={{ mr: 1, mb: 1 }} />
                        
                      );
                    })} */}
                      {/* <Grid container spacing={3}>
                        {types.map((type) => (
                          <>
                            {type === "Techniczne" && (
                              <Grid item xs={12} sm={6}>
                                <Paper elevation={0} sx={{ p: 6 }}>
                                  <Typography>Techniczne</Typography>
                                  <CircularProgressbar
                                    value={sumPercentage("Techniczne")}
                                    text={`${sumPercentage("Techniczne")}%`}
                                    styles={buildStyles({
                                      pathColor: "#d2ab67",
                                      trailColor: "#eee",
                                      textColor: "black",
                                      strokeLinecap: "butt",
                                    })}
                                  />
                                  {skills.map(
                                    ({ name, type, value }) =>
                                      type === "Techniczne" && (
                                        <Box sx={{ mt: 4 }}>
                                          <Typography>{name}</Typography>
                                          <BorderLinearProgressBar
                                            value={value}
                                          />
                                        </Box>
                                      )
                                  )}
                                </Paper>
                              </Grid>
                            )}
                            {type === "Gastronomiczne" && (
                              <Grid item xs={12} sm={6}>
                                <Paper elevation={0} sx={{ p: 6 }}>
                                  <Typography>Gastronomiczne</Typography>
                                  <CircularProgressbar
                                    value={sumPercentage("Gastronomiczne")}
                                    text={`${sumPercentage("Gastronomiczne")}%`}
                                    styles={buildStyles({
                                      pathColor: "#d2ab67",
                                      trailColor: "#eee",
                                      textColor: "black",
                                      strokeLinecap: "butt",
                                    })}
                                  />
                                  {skills.map(
                                    ({ name, type, value }) =>
                                      type === "Gastronomiczne" && (
                                        <Box sx={{ mt: 4 }}>
                                          <Typography>{name}</Typography>
                                          <BorderLinearProgressBar
                                            value={value}
                                          />
                                        </Box>
                                      )
                                  )}
                                </Paper>
                              </Grid>
                            )}
                            {type === "Miękkie" && (
                              <Grid item xs={12} sm={6}>
                                <Paper elevation={0} sx={{ p: 6 }}>
                                  <Typography>Miękkie</Typography>
                                  <CircularProgressbar
                                    value={sumPercentage("Miękkie")}
                                    text={`${sumPercentage("Miękkie")}%`}
                                    styles={buildStyles({
                                      pathColor: "#d2ab67",
                                      trailColor: "#eee",
                                      textColor: "black",
                                      strokeLinecap: "butt",
                                    })}
                                  />
                                  {skills.map(
                                    ({ name, type, value }) =>
                                      type === "Miękkie" && (
                                        <Box sx={{ mt: 4 }}>
                                          <Typography>{name}</Typography>
                                          <BorderLinearProgressBar
                                            value={value}
                                          />
                                        </Box>
                                      )
                                  )}
                                </Paper>
                              </Grid>
                            )}
                          </>
                        ))}
                      </Grid> */}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  component="h4"
                  sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
                >
                  Doświadczenie
                </Typography>
                <Grid container>
                  <Grid item sm={12} sx={{ alignSelf: "center", flexGrow: 1 }}>
                    {experienceList.map((item, id) => {
                      return (
                        <Paper
                          elevation={0}
                          key={id}
                          sx={{
                            p: 2,
                            bgcolor: "background.default",
                            borderBottom: "1px solid #555",
                            borderRadius: 0,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item sm={2} sx={{ alignSelf: "center" }}>
                              <WorkOutlineIcon
                                fontSize="large"
                                sx={{ color: "#d2ab67" }}
                              />
                            </Grid>
                            <Grid item xs={6} sx={{ alignSelf: "center" }}>
                              <Typography variant="h6">{item.title}</Typography>
                              <Typography
                                variant="h7"
                                display="block"
                                gutterBottom
                                sx={{ mb: 0 }}
                              >
                                {item.company} • {item.startMonth}{" "}
                                {item.startYear}
                                {`${
                                  item.isWorking
                                    ? " - Present"
                                    : " - " + item.endMonth + " " + item.endYear
                                }`}
                              </Typography>
                              <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{ mb: 0 }}
                              >
                                {item.location}
                              </Typography>
                              <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{ mb: 0 }}
                              >
                                {item.description}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Paper>
                      );
                    })}
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  component="h4"
                  sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
                >
                  Edukacja
                </Typography>
                <Grid container sm={12}>
                  <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
                    {educationList.map((item, id) => {
                      return (
                        <Paper
                          elevation={0}
                          key={id}
                          sx={{
                            p: 2,
                            bgcolor: "background.default",
                            borderBottom: "1px solid #555",
                            borderRadius: 0,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={2}
                              sm={1}
                              sx={{ alignSelf: "center" }}
                            >
                              <SchoolIcon
                                fontSize="large"
                                sx={{ color: "#d2ab67" }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              sm={9}
                              sx={{ alignSelf: "center" }}
                            >
                              <Typography variant="h6">
                                {item.company}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{ display: "block" }}
                              >
                                {item.school} • {item.degree}
                              </Typography>
                              <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{ mb: 0 }}
                              >
                                {dayjs(item.startDate).format("DD.MM.YYYY")} -{" "}
                                {dayjs(item.endDate).format("DD.MM.YYYY")} •
                                Ocena: {item.grade}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              sm={2}
                              sx={{
                                display: "flex",
                                flexFlow: "column-reverse",
                                alignItems: "end",
                                alignSelf: "center",
                              }}
                            ></Grid>
                          </Grid>
                        </Paper>
                      );
                    })}
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  component="h4"
                  sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
                >
                  Kursy i szkolenia
                </Typography>
                <Grid container sm={12}>
                  <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
                    {externalCourses.map((course, id) => {
                      return (
                        <Paper
                          elevation={0}
                          key={id}
                          sx={{
                            p: 2,
                            bgcolor: "background.default",
                            borderBottom: "1px solid #555",
                            borderRadius: 0,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={2}
                              sm={1}
                              sx={{ alignSelf: "center" }}
                            >
                              <WorkspacePremiumIcon
                                fontSize="large"
                                sx={{ color: "#d2ab67" }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              sm={9}
                              sx={{ alignSelf: "center" }}
                            >
                              <Typography variant="h6">
                                {course.courseName}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{ display: "block" }}
                              >
                                {course.category} • {course.description}
                              </Typography>
                              <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{ mb: 0 }}
                              >
                                {dayjs(course.issueDate).format("DD.MM.YYYY")}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              sm={2}
                              sx={{
                                display: "flex",
                                flexFlow: "column-reverse",
                                alignItems: "end",
                                alignSelf: "center",
                              }}
                            ></Grid>
                          </Grid>
                        </Paper>
                      );
                    })}
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  component="h4"
                  sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
                >
                  Języki obce
                </Typography>
                <Grid container sm={12}>
                  <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
                    {foreignLanguages.map((language, id) => {
                      return (
                        <Paper
                          elevation={0}
                          key={id}
                          sx={{
                            p: 2,
                            bgcolor: "background.default",
                            borderBottom: "1px solid #555",
                            borderRadius: 0,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={2}
                              sm={1}
                              sx={{ alignSelf: "center" }}
                            >
                              <TranslateIcon
                                fontSize="large"
                                sx={{ color: "#d2ab67" }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              sm={9}
                              sx={{ alignSelf: "center" }}
                            >
                              <Typography variant="h6">
                                {language.languageName}
                              </Typography>
                              <Typography
                                variant="caption"
                                sx={{ display: "block" }}
                              >
                                {dayjs(language.issueDate).format("DD.MM.YYYY")}{" "}
                                • {language.description}
                              </Typography>
                              <Typography
                                variant="caption"
                                display="block"
                                gutterBottom
                                sx={{ mb: 0 }}
                              >
                                {language.description}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={2}
                              sm={2}
                              sx={{
                                display: "flex",
                                flexFlow: "column-reverse",
                                alignItems: "end",
                                alignSelf: "center",
                              }}
                            ></Grid>
                          </Grid>
                        </Paper>
                      );
                    })}
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  component="h4"
                  sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
                >
                  Kompetencje społeczne
                </Typography>
                <Grid container sm={12}>
                  <Grid item xs={12} sx={{ alignSelf: "center", mt: 4 }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      useFlexGap
                      flexWrap="wrap"
                      sx={{ paddingBottom: "15px", width: "100%" }}
                    >
                      {socialCompetences.map((item, id) => {
                        return <Chip label={item.name} />;
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  component="h4"
                  sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
                >
                  Kompetencje IT
                </Typography>
                <Grid container sm={12}>
                  <Grid item xs={12} sx={{ alignSelf: "center", mt: 4 }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      useFlexGap
                      flexWrap="wrap"
                      sx={{ paddingBottom: "15px", width: "100%" }}
                    >
                      {itCompetences.map((item, id) => {
                        return <Chip label={item.name} />;
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  component="h4"
                  sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
                >
                  Działalność gospodarcza
                </Typography>
                <Grid container sm={12}>
                  <Grid item xs={12} sx={{ alignSelf: "center", mt: 4 }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      useFlexGap
                      flexWrap="wrap"
                      sx={{ paddingBottom: "15px", width: "100%" }}
                    >
                      {businessActivities.map((item, id) => {
                        return <Chip label={item.name} />;
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  component="h4"
                  sx={{ textAlign: "center", color: "#d2ab67", mt: 3 }}
                >
                  Zainteresowania
                </Typography>
                <Grid container sm={12}>
                  <Grid item xs={12} sx={{ alignSelf: "center", mt: 4 }}>
                    <Stack
                      direction="row"
                      spacing={1}
                      useFlexGap
                      flexWrap="wrap"
                      sx={{ paddingBottom: "15px", width: "100%" }}
                    >
                      {interests.map((item, id) => {
                        return <Chip label={item.name} />;
                      })}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default PdfComponent;
