import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Profile from "./Profile";
import About from "./About";
import Education from "./Education";
import Skills from "./Skills/Skills";
import { NavLink } from "react-router-dom";
import banner from "../../../assets/vccbanner.jpg";
import "../index.css";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";

import { useDispatch, useSelector } from "react-redux";
import { getResume, updateResume } from "../../../Slices/resume";
import UsePrompt from "../../../Components/LeaveAlert";
import { useState } from "react";
import WorkExperience from "./WorkExperience";
import ExternalCourses from "./ExternalCourses";
import ForeignLanguages from "./ForeignLanguages";
import SocialCompetences from "./SocialCompetences";
import ItCompetences from "./ItCompetences";
import BusinessActivities from "./BusinessActivities";
import Interests from "./Intrerests";

function Resume() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const resume = useSelector((state) => state.resume);
  const isEdited = useSelector((state) => state.resume.isEdited);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setIsDirty(isEdited);
  }, [isEdited]);

  useEffect(() => {
    dispatch(getResume({ id: user.user.id, token: user.jwt }));
  }, [user]);

  const handleClick = () => {
    dispatch(
      updateResume({
        id: user.user.id,
        token: user.jwt,
        payload: { ...resume, isEdited: false },
      })
    );
  };

  return (
    <>
      <div
        style={{ backgroundImage: `url(${banner})` }}
        fluid="true"
        className="p-0 top-image"
      ></div>
      <Container>
        <Profile />
        <Grid container spacing={2} sx={{ justifyContent: "center", mt: 4 }}>
          <Grid item xs={12} sm={10} md={8}>
            <Box sx={{ width: "100%" }}>
              <Stack spacing={1}>
                <About />
                {/* <Skills /> */}
                <WorkExperience />
                <Education />
                <ExternalCourses />
                <ForeignLanguages />
                <SocialCompetences />
                <ItCompetences />
                <BusinessActivities />
                <Interests />
              </Stack>
            </Box>
          </Grid>
        </Grid>

        <UsePrompt
          message="Czy napewno chcesz wyjść? twoje zmiany mogą nie zostać zapisane. Aby zapisać zmiany w swoim CV kliknij przycisk Zapisz. Jeżeli chcesz wyjść bez zapisywania kliknik OK"
          when={isDirty}
        />

        {isEdited ? (
          <Grid container sx={{ justifyContent: "center", mt: 6 }}>
            <Button variant="contained" onClick={handleClick}>
              Zapisz
            </Button>
          </Grid>
        ) : (
          <Grid container sx={{ justifyContent: "center", mt: 6 }}>
            <Button variant="contained">
              <NavLink style={{ color: "#fff" }} to="/yourcv/preview">
                Podejrzyj swoje CV
              </NavLink>
            </Button>
          </Grid>
        )}
      </Container>
    </>
  );
}

export default Resume;
