import { Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { SectionPaper } from "../SectionPaper";
import { useDispatch, useSelector } from "react-redux";
import { getPathStats } from "../../Slices/competencePaths";

const StatsBox = () => {
  const dispatch = useDispatch();
  const badgesStats = useSelector((state) => state.competencePahts.pathStats);

  useEffect(() => {
    dispatch(getPathStats());
  }, [dispatch]);

  return (
    <SectionPaper elevation={3}>
      <Grid container spacing={0} sx={{ height: "100%" }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            component="h4"
            variant="h7"
            sx={{
              lineHeight: 1.4,
              textAlign: "left",
              marginLeft: "20px",
            }}
          >
            ilość wydanych odznak / mikropoświadczeń:
          </Typography>
          {badgesStats?.all_issued_badges ? (
            <Typography
              component="span"
              variant="h4"
              sx={{
                color: "#d2ab67",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {badgesStats?.all_issued_badges}
            </Typography>
          ) : (
            <Skeleton variant="rounded" width={40} height={40} sx={{ mr: 2 }} />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            component="h4"
            variant="h7"
            sx={{
              lineHeight: 1.4,
              textAlign: "left",
              marginLeft: "20px",
            }}
          >
            Ilość wydanych certyfikatów:
          </Typography>
          {badgesStats?.gold_issued_badges ? (
            <Typography
              component="span"
              variant="h4"
              sx={{
                color: "#d2ab67",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              {badgesStats?.gold_issued_badges}
            </Typography>
          ) : (
            <Skeleton variant="rounded" width={40} height={40} sx={{ mr: 2 }} />
          )}
        </Grid>
      </Grid>
    </SectionPaper>
  );
};

export default StatsBox;
