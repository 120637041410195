import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { SectionPaper } from "../../Components/SectionPaper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ResponsivePie } from "@nivo/pie";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getLearningPathStats,
  getSinglePathStats,
} from "../../Slices/competencePath";
import { useState } from "react";
import moment from "moment/moment";
import SectionElement from "../../Components/PathSectionElement";
require("moment/locale/pl");

const SingleCompetencePath = () => {
  let { pathId } = useParams();
  const dispatch = useDispatch();
  const jwt = useSelector((state) => state.auth.user.jwt);
  const singlePath = useSelector((state) => state.singlePath.pathStats);
  let pathSections;
  // const [data, setData] = useState();

  const [expanded, setExpanded] = React.useState(false);

  const pathStats = useSelector((state) => state.singlePath.learningPathStats);

  if (singlePath) {
    pathSections = Object.fromEntries(
      Object.entries(singlePath)?.filter(
        ([klucz, wartosc]) => typeof wartosc === "object" && wartosc !== null
      )
    );
  }

  useEffect(() => {
    dispatch(getSinglePathStats({ id: pathId, token: jwt }));
    dispatch(getLearningPathStats({ id: pathId }));
    return () => {};
  }, [dispatch, pathId, jwt]);

  const CenteredMetric = ({ centerX, centerY, dataWithArc }) => {
    const metricValue = dataWithArc[0].value
      ? `${dataWithArc[0].value}%`
      : "0%";
    const metricLabelTop = "Jesteś lepszy od";
    const metricLabelBottom = "uczestników";

    return (
      <g transform={`translate(${centerX},${centerY})`} textAnchor="middle">
        <text fontSize={9} fill="#d2ab67" dy="-1.1em">
          {metricLabelTop}
        </text>
        <text fontSize={18} fill="#d2ab67" dy="0.5em">
          {metricValue}
        </text>
        <text fontSize={9} fill="#d2ab67" dy="2.1em">
          {metricLabelBottom}
        </text>
      </g>
    );
  };

  return (
    <main>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 6,
        }}
      >
        <Typography
          component="h1"
          variant="h4"
          align="center"
          color="text.primary"
        >
          Ścieżki kompetencyjna “{singlePath?.name}”
        </Typography>
      </Box>
      <Container spacing={0}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <SectionPaper elevation={3}>
              <Grid container>
                <Grid item xs={6}>
                  <div style={{ height: "200px" }}>
                    <Typography
                      component="h4"
                      variant="subtitle2"
                      align="center"
                      color="text.primary"
                    >
                      Umiejętności twarde
                    </Typography>
                    <ResponsivePie
                      data={[
                        {
                          id: "Profile poniżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.hardSkillsCount.userPercentage}`,
                          value: pathStats?.hardSkillsCount.userPercentage,
                          color: "hsl(0, 93%, 1%)",
                        },
                        {
                          id: "Profile powyżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.hardSkillsCount.userPercentage}`,
                          value:
                            100 - pathStats?.hardSkillsCount.userPercentage,
                          color: "hsl(25, 70%, 50%)",
                        },
                      ]}
                      margin={{
                        top: 10,
                        right: 10,
                        bottom: 10,
                        left: 10,
                      }}
                      innerRadius={0.8}
                      padAngle={0.7}
                      cornerRadius={0}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      colors={["#FFA726", "#ededed"]}
                      enableArcLinkLabels={false}
                      layers={[
                        "arcs",
                        // "arcLabels",
                        "arcLinkLabels",
                        // "legends",
                        CenteredMetric,
                      ]}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sx={{ height: "250px" }}>
                  <div style={{ height: "200px" }}>
                    <Typography
                      component="h4"
                      variant="subtitle2"
                      align="center"
                      color="text.primary"
                    >
                      Umiejętności miękkie
                    </Typography>
                    <ResponsivePie
                      data={[
                        {
                          id: "Profile poniżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.softSkillsCount.userPercentage}`,
                          value: pathStats?.softSkillsCount.userPercentage,
                          color: "hsl(25, 70%, 50%)",
                        },
                        {
                          id: "Profile powyżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.softSkillsCount.userPercentage}`,
                          value:
                            100 - pathStats?.softSkillsCount.userPercentage,
                          color: "hsl(25, 70%, 50%)",
                        },
                      ]}
                      margin={{
                        top: 10,
                        right: 10,
                        bottom: 10,
                        left: 10,
                      }}
                      innerRadius={0.8}
                      padAngle={0.7}
                      cornerRadius={0}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      colors={["#FFA726", "#ededed"]}
                      enableArcLinkLabels={false}
                      layers={[
                        "arcs",
                        // "arcLabels",
                        "arcLinkLabels",
                        // "legends",
                        CenteredMetric,
                      ]}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sx={{ height: "250px" }}>
                  <div style={{ height: "200px" }}>
                    <Typography
                      component="h4"
                      variant="subtitle2"
                      align="center"
                      color="text.primary"
                    >
                      Ilość obszarów kompetencyjnych
                    </Typography>
                    <ResponsivePie
                      data={[
                        {
                          id: "Profile poniżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.subGroupCount.userPercentage}`,
                          value: pathStats?.subGroupCount.userPercentage,
                          color: "hsl(25, 70%, 50%)",
                        },
                        {
                          id: "Profile powyżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.subGroupCount.userPercentage}`,
                          value: 100 - pathStats?.subGroupCount.userPercentage,
                          color: "hsl(25, 70%, 50%)",
                        },
                      ]}
                      margin={{
                        top: 10,
                        right: 10,
                        bottom: 10,
                        left: 10,
                      }}
                      innerRadius={0.8}
                      padAngle={0.7}
                      cornerRadius={0}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      colors={["#FFA726", "#ededed"]}
                      enableArcLinkLabels={false}
                      layers={[
                        "arcs",
                        // "arcLabels",
                        "arcLinkLabels",
                        // "legends",
                        CenteredMetric,
                      ]}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sx={{ height: "250px" }}>
                  <div style={{ height: "200px" }}>
                    <Typography
                      component="h4"
                      variant="subtitle2"
                      align="center"
                      color="text.primary"
                    >
                      Średnia obszarów kompetencyjnych
                    </Typography>
                    <ResponsivePie
                      data={[
                        {
                          id: "Profile poniżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.subGroupAverage.userPercentage}`,
                          value: pathStats?.subGroupAverage.userPercentage,
                          color: "hsl(25, 70%, 50%)",
                        },
                        {
                          id: "Profile powyżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.subGroupAverage.userPercentage}`,
                          value:
                            100 - pathStats?.subGroupAverage.userPercentage,
                          color: "hsl(25, 70%, 50%)",
                        },
                      ]}
                      margin={{
                        top: 10,
                        right: 10,
                        bottom: 10,
                        left: 10,
                      }}
                      innerRadius={0.8}
                      padAngle={0.7}
                      cornerRadius={0}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      colors={["#FFA726", "#ededed"]}
                      enableArcLinkLabels={false}
                      layers={[
                        "arcs",
                        // "arcLabels",
                        "arcLinkLabels",
                        // "legends",
                        CenteredMetric,
                      ]}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sx={{ height: "250px" }}>
                  <div style={{ height: "200px" }}>
                    <Typography
                      component="h4"
                      variant="subtitle2"
                      align="center"
                      color="text.primary"
                    >
                      Ilość kwalifikacji
                    </Typography>
                    <ResponsivePie
                      data={[
                        {
                          id: "Profile poniżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.qualificationCount.userPercentage}`,
                          value: pathStats?.qualificationCount.userPercentage,
                          color: "hsl(25, 70%, 50%)",
                        },
                        {
                          id: "Profile powyżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.qualificationCount.userPercentage}`,
                          value:
                            100 - pathStats?.qualificationCount.userPercentage,
                          color: "hsl(25, 70%, 50%)",
                        },
                      ]}
                      margin={{
                        top: 10,
                        right: 10,
                        bottom: 10,
                        left: 10,
                      }}
                      innerRadius={0.8}
                      padAngle={0.7}
                      cornerRadius={0}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      colors={["#FFA726", "#ededed"]}
                      enableArcLinkLabels={false}
                      layers={[
                        "arcs",
                        // "arcLabels",
                        "arcLinkLabels",
                        // "legends",
                        CenteredMetric,
                      ]}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} sx={{ height: "250px" }}>
                  <div style={{ height: "200px" }}>
                    <Typography
                      component="h4"
                      variant="subtitle2"
                      align="center"
                      color="text.primary"
                    >
                      Poziom PRK
                    </Typography>
                    <ResponsivePie
                      data={[
                        {
                          id: "Profile poniżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.prkLevelAverage.userPercentage}`,
                          value: pathStats?.prkLevelAverage.userPercentage,
                          color: "hsl(25, 70%, 50%)",
                        },
                        {
                          id: "Profile powyżej twojego rozwoju ścieżki (%)",
                          label: `PRK${pathStats?.prkLevelAverage.userPercentage}`,
                          value:
                            100 - pathStats?.prkLevelAverage.userPercentage,
                          color: "hsl(25, 70%, 50%)",
                        },
                      ]}
                      margin={{
                        top: 10,
                        right: 10,
                        bottom: 10,
                        left: 10,
                      }}
                      innerRadius={0.8}
                      padAngle={0.7}
                      cornerRadius={0}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={{
                        from: "color",
                        modifiers: [["darker", 0.2]],
                      }}
                      colors={["#FFA726", "#ededed"]}
                      enableArcLinkLabels={false}
                      layers={[
                        "arcs",
                        // "arcLabels",
                        "arcLinkLabels",
                        // "legends",
                        CenteredMetric,
                      ]}
                    />
                  </div>
                </Grid>
              </Grid>
            </SectionPaper>
          </Grid>
          <Grid item xs={6}>
            <Grid container gap={4}>
              {pathSections &&
                Object.keys(pathSections).map((section, i) => (
                  <SectionElement
                    key={i}
                    name={section}
                    section={pathSections[section]}
                    expanded={expanded}
                    setExpanded={setExpanded}
                  />
                ))}

              {/* <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                sx={{
                  width: "100%",
                  borderRadius: "7px",
                  boxShadow:
                    "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 10px 1px rgba(0, 0, 0, 0.14), 0px -3px 3px -3px rgba(0, 0, 0, 0.20)",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    background: "#bdbdbd",
                    borderRadius: expanded === "panel1" ? "7px 7px 0 0" : "7px",
                  }}
                >
                  <Typography
                    style={{
                      zIndex: 1,
                    }}
                  >
                    Kwalifikacje bazowe |{" "}
                    {`${calculatePercentage(singlePath?.kwalifikacje_bazowe)}%`}
                  </Typography>
                  <div
                    style={{
                      width: `${calculatePercentage(
                        singlePath?.kwalifikacje_bazowe
                      )}%`,
                      height: "100%",
                      background: "#D2AB67",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 0,
                      borderRadius: "7px",
                    }}
                  ></div>
                </AccordionSummary>

                <AccordionDetails>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    {singlePath?.kwalifikacje_bazowe?.map((element) => (
                      <Link
                        to={
                          element.owned
                            ? `/odznaki/${element.id}`
                            : `/odznaki-nieodblokowane/${element.id}`
                        }
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem sx={{ opacity: element.owned ? 1 : 0.3 }}>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                borderRadius: 0,
                                height: "46px",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img
                                src={element.image?.url}
                                alt={element.badgeName}
                                width="100%"
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={element.badgeName}
                            secondary={
                              element.owned
                                ? moment(element.createdAt).format("LL")
                                : "Odznaka jeszcze nie odblokowana"
                            }
                          />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                sx={{
                  width: "100%",
                  "&:before": { height: "0px" },
                  borderRadius: "7px",
                  boxShadow:
                    "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 10px 1px rgba(0, 0, 0, 0.14), 0px -3px 3px -3px rgba(0, 0, 0, 0.20)",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography
                    style={{
                      zIndex: 1,
                    }}
                  >
                    Kwalifikacje uzupełniające |{" "}
                    {`${calculatePercentage(
                      singlePath?.kwalifikacje_uzupelniajace
                    )}%`}
                  </Typography>
                  <div
                    style={{
                      width: `${calculatePercentage(
                        singlePath?.kwalifikacje_uzupelniajace
                      )}%`,
                      height: "100%",
                      background: "#D2AB67",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 0,
                      borderRadius: "7px",
                    }}
                  ></div>
                </AccordionSummary>
                <AccordionDetails>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    {singlePath?.kwalifikacje_uzupelniajace?.map((element) => (
                      <Link
                        to={`/odznaki/${element.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem sx={{ opacity: element.owned ? 1 : 0.3 }}>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                borderRadius: 0,
                                height: "46px",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img
                                src={element.image?.url}
                                alt={element.badgeName}
                                width="100%"
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={element.badgeName}
                            secondary={
                              element.owned
                                ? moment(element.createdAt).format("LL")
                                : "Odznaka jeszcze nie odblokowana"
                            }
                          />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                sx={{
                  width: "100%",
                  "&:before": { height: "0px" },
                  borderRadius: "7px",
                  boxShadow:
                    "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 10px 1px rgba(0, 0, 0, 0.14), 0px -3px 3px -3px rgba(0, 0, 0, 0.20)",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography
                    style={{
                      zIndex: 1,
                    }}
                  >
                    Języki obce |{" "}
                    {`${calculatePercentage(singlePath?.jezyki_obce)}%`}
                  </Typography>
                  <div
                    style={{
                      width: `${calculatePercentage(singlePath?.jezyki_obce)}%`,
                      height: "100%",
                      background: "#D2AB67",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 0,
                      borderRadius: "7px",
                    }}
                  ></div>
                </AccordionSummary>
                <AccordionDetails>
                  <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                    {singlePath?.jezyki_obce?.map((element) => (
                      <Link
                        to={`/odznaki/${element.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <ListItem sx={{ opacity: element.owned ? 1 : 0.3 }}>
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                borderRadius: 0,
                                height: "46px",
                                backgroundColor: "#fff",
                              }}
                            >
                              <img
                                src={element.image?.url}
                                alt={element.badgeName}
                                width="100%"
                              />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={element.badgeName}
                            secondary={
                              element.owned
                                ? moment(element.createdAt).format("LL")
                                : "Odznaka jeszcze nie odblokowana"
                            }
                          />
                        </ListItem>
                      </Link>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>

              <Accordion
                disabled
                sx={{
                  "&:before": { height: "0px" },
                  borderRadius: "7px",
                  boxShadow:
                    "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 10px 1px rgba(0, 0, 0, 0.14), 0px -3px 3px -3px rgba(0, 0, 0, 0.20)",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel4a-content"
                  id="panel4a-header"
                >
                  <Typography>Kwalifikacje bazowe | 78%</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default SingleCompetencePath;
