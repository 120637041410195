import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../Actions/services";
import { setMessage } from "./message";
import badgeService from "../Actions/openBadgeServices";
const user = JSON.parse(localStorage.getItem("user"));
const badge = JSON.parse(sessionStorage.getItem("badges"));

export const getCompetenceProfile = createAsyncThunk(
  "competenceProfile",
  async ({ id, token }, thunkAPI) => {
    try {
      const data = await userService.getProfile(id, token);
      return data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCompetenceProfileStats = createAsyncThunk(
  "competenceProfileStats",
  async (profileId, thunkAPI) => {
    try {
      const data = await badgeService.getProfileScores({ profileId });
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getProfileSubgroups = createAsyncThunk(
  "profileSubgroups",
  async ({ profileId, section }, thunkAPI) => {
    try {
      const data = await badgeService.getProfileSubgroups({
        profileId,
        section,
      });

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  profileStats: [],
};

const competencePathSlice = createSlice({
  name: "competenceProfile",
  initialState,
  extraReducers: {
    [getCompetenceProfile.pending]: (state, action) => {
      return state;
    },
    [getCompetenceProfile.fulfilled]: (state, action) => {
      return (state = { ...state, ...action.payload });
    },
    [getCompetenceProfile.rejected]: (state, action) => {
      return (state = []);
    },
    [getCompetenceProfileStats.pending]: (state, action) => {
      return state;
    },
    [getCompetenceProfileStats.fulfilled]: (state, action) => {
      return (state = { ...state, profileStats: action.payload });
    },
    [getCompetenceProfileStats.rejected]: (state, action) => {
      return (state = []);
    },
    [getProfileSubgroups.pending]: (state, action) => {
      return state;
    },
    [getProfileSubgroups.fulfilled]: (state, action) => {
      return (state = { ...state, profileSubgroups: action.payload });
    },
    [getProfileSubgroups.rejected]: (state, action) => {
      return (state = []);
    },
  },
});
const { reducer } = competencePathSlice;
export default reducer;
