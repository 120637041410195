import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment/moment";

const matrix = [
  {
    type: "kwalifikacje_bazowe",
    translation: "Kwalifikacje Bazowe",
  },
  {
    type: "kwalifikacje_uzupelniajace",
    translation: "Kwalifikacje Uzupełniające",
  },
  {
    type: "jezyki_obce",
    translation: "Języki Obce",
  },
  {
    type: "kompetencje_it",
    translation: "Kompetencje IT",
  },
  {
    type: "kompetencje_spoleczne",
    translation: "Kompetencje Społeczne",
  },
  {
    type: "dzialalnosc_gospodarcza",
    translation: "Dzialalność Gospodarcza",
  },
  {
    type: "sekcja_doswiadczenie",
    translation: "Sekcja doświadczenie",
  },
  {
    type: "sekcja_dzialalnosc_gospodarcza",
    translation: "Sekcja dzialalność gospodarcza",
  },
  {
    type: "sekcja_jezyki",
    translation: "Sekcja języki",
  },
  {
    type: "sekcja_kompetencje_it",
    translation: "Sekcja kompetencje IT",
  },
  {
    type: "sekcja_kompetencje_spoleczne",
    translation: "Sekcja kompetencje społeczne",
  },
  {
    type: "sekcja_kursy",
    translation: "Sekcja kursy",
  },
  {
    type: "sekcja_kwalifikacje",
    translation: "Sekcja kwalifikacje",
  },
  {
    type: "sekcja_wyksztalcenie",
    translation: "Sekcja wykształcenie",
  },
  {
    type: "sekcja_zainteresowania",
    translation: "Sekcja zainteresowania",
  },
];

const SectionElement = ({ name, section, expanded, setExpanded }) => {
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  let calculatePercentage = (array) => {
    if (array) {
      const userBadges = array.filter((el) => el.owned).length;
      const allBadges = array.length;
      if (userBadges !== 0) {
        return (userBadges / allBadges) * 100;
      }
      return 0;
    }
  };

  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      setProgress(calculatePercentage(section));
    }, 150);
    // return () => {
    //   setProgress(0);
    // };
  }, [section]);

  return (
    <Accordion
      expanded={expanded === name}
      onChange={handleChange(name)}
      sx={{
        width: "100%",
        "&:before": { height: "0px" },
        borderRadius: "7px",
        boxShadow:
          "0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 4px 10px 1px rgba(0, 0, 0, 0.14), 0px -3px 3px -3px rgba(0, 0, 0, 0.20)",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${name}-content`}
        id={`${name}-header`}
      >
        <Typography
          style={{
            zIndex: 1,
          }}
        >
          {matrix.find((item) => item.type === name).translation} |{" "}
          {`${Math.floor(calculatePercentage(section))}%`}
        </Typography>
        <div
          id="progressBar"
          style={{
            width: `${progress}%`,
            transition: `width 1s ease`,
            height: "100%",
            background: "#D2AB67",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 0,
            borderRadius: "7px",
          }}
        ></div>
      </AccordionSummary>
      <AccordionDetails>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {section.map((element, i) => (
            <Link
              key={i}
              to={
                element.owned
                  ? `/odznaki/${element.id}`
                  : `/odznaki-do-odblokowania/${element.id}`
              }
              style={{ textDecoration: "none" }}
            >
              <ListItem sx={{ opacity: element.owned ? 1 : 0.3 }}>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      borderRadius: 0,
                      height: "46px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <img
                      src={element.image?.url}
                      alt={element.badgeName}
                      width="100%"
                    />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={element.badgeName}
                  secondary={
                    element.owned
                      ? moment(element.issuanceDate).format("LL")
                      : "Odznaka jeszcze nie odblokowana"
                  }
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default SectionElement;
