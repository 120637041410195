import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";

import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  addSocialCompetence,
  removeSocialCompetence,
} from "../../../Slices/resume";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const socialCompetencesList = [
  "Komunikacja werbalna (zdolność do jasnego i efektywnego wyrażania myśli, uczuć i informacji słowami.)",
  "Komunikacja niewerbalna (umiejętność interpretowania i używania mowy ciała, kontaktu wzrokowego, gestów i mimiki do przekazywania i odbierania informacji.)",
  "Słuchanie aktywne (zdolność do skupienia uwagi na mówcy, zrozumienia przekazywanych informacji i odpowiedniego na nie reagowania.)",
  "Rozwiązywanie konfliktów (umiejętność rozpoznawania, adresowania i rozwiązywania różnic lub sporów w sposób konstruktywny.)",
  "Praca zespołowa (zdolność do efektywnej współpracy z innymi w celu osiągnięcia wspólnych celów.)",
  "Negocjacje (umiejętność osiągania porozumień i kompromisów w sposób, który jest satysfakcjonujący dla wszystkich stron.)",
  "Asertywność (zdolność do wyrażania własnych myśli i uczuć w sposób bezpośredni, ale szanujący granice innych.)",
  "Umiejętność budowania relacji (zdolność do nawiązywania, rozwijania i utrzymywania zdrowych, pozytywnych relacji interpersonalnych.)",
  "Zarządzanie stresem (miejętność radzenia sobie z presją i napięciami w sposób, który minimalizuje negatywny wpływ na siebie i innych.)",
  "Adaptacja i elastyczność (zdolność do dostosowywania się do zmieniających się sytuacji i środowisk.)",
  "Zarządzanie emocjami (umiejętność rozpoznawania, rozumienia i właściwego zarządzania własnymi emocjami i reakcjami.)",
  "Umiejętność motywowania (zdolność do inspiracji i zachęcania innych do działania lub do osiągnięcia celów.)",
  "Kulturowa świadomość (zrozumienie i szacunek dla różnorodności kulturowej i umiejętność efektywnej interakcji w różnorodnym środowisku.)",
  "Perswazja i wpływanie (umiejętność przekonywania innych do określonych punktów widzenia, zachowań lub decyzji.)",
  "INNE",
];

function SocialCompetences() {
  const socialCompetences = useSelector(
    (state) => state.resume.socialCompetences
  );
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [Alert, setAlert] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [input, setInput] = useState("");
  const [ownInput, setOwnInput] = useState(false);

  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setOwnInput(false);
    setInput("");
  };
  const handleShow = () => setShow(true);
  const [isEdit, setIsEdit] = useState(false);
  const [validated, setValidated] = useState(false);

  const handleInput = (e) => {
    setInput(e.target.value);
  };

  const handleSkills = (e) => {
    e.preventDefault();
    const valid = e.currentTarget;
    if (!valid.checkValidity()) {
      setValidated(true);
    } else {
      setIsEdit(true);
      dispatch(
        addSocialCompetence({
          name: input,
          value: "0",
          type: "kompetencje społeczne",
        })
      );
      setInput("");
    }
    setOwnInput(false);
  };

  const handleChange = (e) => {
    if (e.target.value === "INNE") {
      setOwnInput(true);
      setInput("");
    } else {
      setOwnInput(false);
      setInput(e.target.value);
    }
  };

  const handleAlertClose = () => setAlert(false);
  const handleAlert = (id) => {
    setDeleteId(id);
    setAlert(true);
  };
  const handleDelete = (id) => {
    dispatch(removeSocialCompetence(id));
    setAlert(false);
  };
  useEffect(() => {
    if (socialCompetences?.length === 0) {
      setIsEdit(false);
    }
  }, [socialCompetences]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    paddingTop: "15px",
    paddingBottom: "15px",
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Item>
        <Grid container>
          <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
            <Typography variant="h6">Kompetencje społeczne</Typography>
          </Grid>
          <Grid justifyContent="flex-end">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleShow}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Item>

      <Grid container>
        <Grid
          item
          xs={12}
          sx={{ borderBottom: "1px solid #0000001f", mt: 1, pb: 2 }}
        >
          <Box
            direction="row"
            spacing={1}
            sx={{ paddingBottom: "15px", flexWrap: "wrap" }}
          >
            {socialCompetences?.map((item, id) => {
              return (
                <Chip
                  key={id}
                  label={item.name}
                  size="small"
                  variant="outlined"
                  sx={{ ml: 1, mb: 1 }}
                />
              );
            })}
          </Box>
        </Grid>
      </Grid>

      <Modal open={show} onClose={handleClose}>
        <Box sx={style} component="form" noValidate autoComplete="off">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Kompetencje społeczne
          </Typography>

          <Grid item xs={12} sx={{ alignSelf: "center" }}>
            <Stack
              direction="row"
              spacing={1}
              useFlexGap
              flexWrap="wrap"
              sx={{ paddingBottom: "15px", width: "100%" }}
            >
              {socialCompetences?.map((item, id) => (
                <Chip
                  key={id}
                  label={item.name}
                  onDelete={() => handleDelete(id)}
                  deleteIcon={<DeleteIcon />}
                  onClick={() => {
                    handleAlert(id);
                  }}
                />
              ))}
            </Stack>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={9} sx={{ my: 1 }}>
                {ownInput ? (
                  <TextField
                    fullWidth
                    label="Wpisz umiejętność"
                    id="fullWidth"
                    value={input}
                    placeholder="Napisz coś o sobie"
                    onChange={handleInput}
                  />
                ) : (
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="courseCategory">
                      Wybierz umiejętność
                    </InputLabel>
                    <Select
                      labelId="courseCategory"
                      id="courseCategory"
                      defaultValue={""}
                      value={input}
                      label="Wybierz umiejętność"
                      onChange={(e) => handleChange(e)}
                      sx={{ width: "100%" }}
                    >
                      <MenuItem value="" key="0">
                        Wybierz umiejętność
                      </MenuItem>
                      {socialCompetencesList.map((competence, index) => {
                        return (
                          <MenuItem key={index + 1} value={competence}>
                            {competence}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={3}>
                <Button variant="outlined" onClick={handleSkills}>
                  Dodaj
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Button variant="outlined" onClick={handleClose} sx={{ mt: "10px" }}>
            Zapisz zmiany
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default SocialCompetences;

// import React, { useState, useEffect } from "react";
// // import Modal from "react-bootstrap/Modal";
// import { ImCheckmark, ImCross } from "react-icons/im";
// import { useSelector, useDispatch } from "react-redux";

// import Paper from "@mui/material/Paper";
// import { styled } from "@mui/material/styles";
// import Fab from "@mui/material/Fab";
// import AddIcon from "@mui/icons-material/Add";
// import DeleteIcon from "@mui/icons-material/Delete";
// import Chip from "@mui/material/Chip";
// import Stack from "@mui/material/Stack";
// import Modal from "@mui/material/Modal";
// import Box from "@mui/material/Box";
// import {
//   Button,
//   Grid,
//   TextField,
//   Typography,
//   IconButton,
//   Select,
//   FormControl,
//   InputLabel,
//   MenuItem,
// } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import {
//   addEducation,
//   addSkill,
//   editEducation,
//   removeSkill,
// } from "../../../Slices/resume";
// import * as yup from "yup";
// import { Controller, FormProvider, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   boxShadow: 24,
//   p: 4,
// };

// const socialCompetencesList = [
//   "Komunikacja werbalna (zdolność do jasnego i efektywnego wyrażania myśli, uczuć i informacji słowami.)",
//   "Komunikacja niewerbalna (umiejętność interpretowania i używania mowy ciała, kontaktu wzrokowego, gestów i mimiki do przekazywania i odbierania informacji.)",
//   "Słuchanie aktywne (zdolność do skupienia uwagi na mówcy, zrozumienia przekazywanych informacji i odpowiedniego na nie reagowania.)",
//   "Rozwiązywanie konfliktów (umiejętność rozpoznawania, adresowania i rozwiązywania różnic lub sporów w sposób konstruktywny.)",
//   "Praca zespołowa (zdolność do efektywnej współpracy z innymi w celu osiągnięcia wspólnych celów.)",
//   "Negocjacje (umiejętność osiągania porozumień i kompromisów w sposób, który jest satysfakcjonujący dla wszystkich stron.)",
//   "Asertywność (zdolność do wyrażania własnych myśli i uczuć w sposób bezpośredni, ale szanujący granice innych.)",
//   "Umiejętność budowania relacji (zdolność do nawiązywania, rozwijania i utrzymywania zdrowych, pozytywnych relacji interpersonalnych.)",
//   "Zarządzanie stresem (miejętność radzenia sobie z presją i napięciami w sposób, który minimalizuje negatywny wpływ na siebie i innych.)",
//   "Adaptacja i elastyczność (zdolność do dostosowywania się do zmieniających się sytuacji i środowisk.)",
//   "Zarządzanie emocjami (umiejętność rozpoznawania, rozumienia i właściwego zarządzania własnymi emocjami i reakcjami.)",
//   "Umiejętność motywowania (zdolność do inspiracji i zachęcania innych do działania lub do osiągnięcia celów.)",
//   "Kulturowa świadomość (zrozumienie i szacunek dla różnorodności kulturowej i umiejętność efektywnej interakcji w różnorodnym środowisku.)",
//   "Perswazja i wpływanie (umiejętność przekonywania innych do określonych punktów widzenia, zachowań lub decyzji.)",
//   "INNE",
// ];

// function SocialCompetences() {
//   const skills = useSelector((state) => state.resume.skills);
//   const dispatch = useDispatch();
//   const [show, setShow] = useState(false);
//   const [Alert, setAlert] = useState(false);
//   const [deleteId, setDeleteId] = useState(null);
//   const [input, setInput] = useState("");
//   const [ownInput, setOwnInput] = useState(false);

//   const [socialCompetences, setSocialCompetences] = useState({
//     skillNameInput: "",
//     isEdit: false,
//   });

//   const SocialCompetencesSchema = yup.object().shape({
//     skillNameInput: yup
//       .string()
//       .required("Wybierz kompetencje z listy lub wybierz inne")
//       .min(3, "Pole umiejętność jest za krótkie"),
//   });

//   const form = useForm({
//     mode: "onChange",
//     resolver: yupResolver(SocialCompetencesSchema),
//   });

//   useEffect(() => {
//     console.log(socialCompetences);
//     console.log(form);
//   }, [show]);

//   // const [skills, setSkills] = useState([]);

//   const handleClose = () => {
//     setShow(false);
//     setValidated(false);
//     setOwnInput(false);
//     setInput("");
//   };
//   const handleShow = () => setShow(true);
//   const [isEdit, setIsEdit] = useState(false);
//   const [validated, setValidated] = useState(false);

//   const onSubmit = (data) => {
//     console.log(data, form);
//     console.log("submited", socialCompetences);
//     console.log("submited data", data);
//     if (socialCompetences.isEdit) {
//       dispatch(editEducation({ ...socialCompetences, ...data }));
//     } else {
//       dispatch(addEducation(data));
//     }
//     setShow(false);
//     setSocialCompetences({
//       id: "",
//       skillNameInput: "",
//       isEdit: false,
//     });
//   };

//   const onError = (error) => console.log(error);

//   const handleInput = (e) => {
//     setInput(e.target.value);
//   };

//   const handleSkills = (e) => {
//     console.log("socialCompetences", socialCompetences);
//     e.preventDefault();
//     const valid = e.currentTarget;
//     if (!valid.checkValidity()) {
//       setValidated(true);
//     } else {
//       setIsEdit(true);
//       dispatch(
//         addSkill({ name: input, value: "0", type: "kompetencje społeczne" })
//       );
//       setInput("");
//     }
//     setOwnInput(false);
//   };

//   const handleChange = (e) => {
//     console.log(e);
//     if (e.target.value === "INNE") {
//       setOwnInput(true);
//       setInput("");
//     } else {
//       setOwnInput(false);
//       setInput(e.target.value);
//     }
//   };

//   const handleAlertClose = () => setAlert(false);
//   const handleAlert = (id) => {
//     setDeleteId(id);
//     setAlert(true);
//   };
//   const handleDelete = (id) => {
//     dispatch(removeSkill(id));
//     setAlert(false);
//   };
//   useEffect(() => {
//     if (skills.length === 0) {
//       setIsEdit(false);
//     }
//   }, [skills]);

//   const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     backgroundColor: "#f8f9fa",
//     paddingTop: "15px",
//     paddingBottom: "15px",
//     textAlign: "left",
//     color: theme.palette.text.secondary,
//   }));

//   return (
//     <>
//       <Item>
//         <Grid container>
//           <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
//             <Typography variant="h6">Kompetencje społeczne</Typography>
//           </Grid>
//           <Grid justifyContent="flex-end">
//             <Fab
//               size="small"
//               color="primary"
//               aria-label="add"
//               onClick={handleShow}
//             >
//               <AddIcon />
//             </Fab>
//           </Grid>
//         </Grid>
//       </Item>

//       <Grid container>
//         <Grid
//           item
//           xs={12}
//           sx={{ borderBottom: "1px solid #0000001f", mt: 1, pb: 2 }}
//         >
//           <Box
//             direction="row"
//             spacing={1}
//             sx={{ paddingBottom: "15px", flexWrap: "wrap" }}
//           >
//             {skills.map((item, id) => {
//               return (
//                 <Chip
//                   key={id}
//                   label={item.name}
//                   size="small"
//                   variant="outlined"
//                   sx={{ ml: 1, mb: 1 }}
//                 />
//               );
//             })}
//           </Box>
//         </Grid>
//       </Grid>

//       <Modal open={show} onClose={handleClose}>
//         <Box
//           sx={style}
//           component="form"
//           noValidate
//           autoComplete="off"
//           onSubmit={form.handleSubmit(onSubmit, onError)}
//         >
//           <IconButton
//             aria-label="close"
//             onClick={handleClose}
//             sx={{
//               position: "absolute",
//               right: 8,
//               top: 8,
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//           <Typography variant="h4" sx={{ mb: 2 }}>
//             Kompetencje społeczne
//           </Typography>

//           <Grid item xs={12} sx={{ alignSelf: "center" }}>
//             <Stack
//               direction="row"
//               spacing={1}
//               useFlexGap
//               flexWrap="wrap"
//               sx={{ paddingBottom: "15px", width: "100%" }}
//             >
//               {skills.map((item, id) => (
//                 <Chip
//                   label={item.name}
//                   onDelete={() => handleDelete(id)}
//                   deleteIcon={<DeleteIcon />}
//                   onClick={() => {
//                     handleAlert(id);
//                   }}
//                 />
//               ))}
//             </Stack>
//             <Grid container spacing={1} alignItems="center">
//               <Grid item xs={9} sx={{ my: 1 }}>
//                 <FormProvider {...form}>
//                   {ownInput ? (
//                     <Controller
//                       name="skillNameInput"
//                       defaultValue={socialCompetences.skillNameInput}
//                       rules={{ required: true }}
//                       shouldUnregister="true"
//                       render={({
//                         field: { value, onChange },
//                         fieldState: { error },
//                       }) => (
//                         <TextField
//                           fullWidth
//                           label="Wpisz umiejętność"
//                           id="fullWidth"
//                           value={value}
//                           onChange={onChange}
//                           helperText={error && error.message}
//                           error={error !== undefined}
//                           placeholder="Napisz coś o sobie"
//                           // onChange={handleInput}
//                         />
//                       )}
//                     />
//                   ) : (
//                     <FormControl sx={{ width: "100%" }}>
//                       <InputLabel id="courseCategory">
//                         Wybierz umiejętność
//                       </InputLabel>

//                       <Controller
//                         name="skillNameInput"
//                         defaultValue={socialCompetences.skillNameInput}
//                         rules={{ required: true }}
//                         shouldUnregister="true"
//                         render={({
//                           field: { value, onChange },
//                           fieldState: { error },
//                         }) => (
//                           <Select
//                             labelId="courseCategory"
//                             id="courseCategory"
//                             defaultValue={""}
//                             value={value}
//                             onChange={onChange}
//                             helperText={error && error.message}
//                             error={error !== undefined}
//                             label="Wybierz umiejętność"
//                             // onChange={(e) => handleChange(e)}
//                             sx={{ width: "100%" }}
//                           >
//                             <MenuItem value="" key="0">
//                               Wybierz umiejętność
//                             </MenuItem>
//                             {socialCompetencesList.map((competence, index) => {
//                               return (
//                                 <MenuItem key={index + 1} value={competence}>
//                                   {competence}
//                                 </MenuItem>
//                               );
//                             })}
//                           </Select>
//                         )}
//                       />
//                     </FormControl>
//                   )}
//                 </FormProvider>
//               </Grid>
//               <Grid item xs={3}>
//                 <Button variant="outlined" onClick={handleSkills}>
//                   Dodaj
//                 </Button>
//               </Grid>
//             </Grid>
//           </Grid>

//           <Button variant="outlined" type="submit" sx={{ mt: "10px" }}>
//             Zapisz zmiany
//           </Button>
//         </Box>
//       </Modal>
//     </>
//   );
// }

// export default SocialCompetences;
