import "./App.css";
import Register from "./Pages/Register";
import Login from "./Pages/Login";
import Badges from "./Pages/Badges";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ProtectedRoute from "./Components/PrivateRoute";

import { useSelector } from "react-redux";
import Qualifications from "./Pages/Qualifications";
import SingleCertificate from "./Pages/SingleCertificate";
import Error404 from "./Components/404";
import Profile from "./Pages/Profile";
import ResetPassword from "./Pages/ResetPassword";
import TermsAndConditions from "./Pages/TermsAndConditions";
import Certificates from "./Pages/Certificates/index";
import Copyright from "./Components/Copyright";
import ForgotPassword from "./Pages/ForgotPassword";
import EmailConfirmation from "./Components/EmailConfirmed";
import RegisterGuest from "./Pages/RegisterGuest";
import EmailNotConfirmed from "./Components/EmailNotConfirmed";
import AboutUs from "./Pages/AboutUs";
import Resume from "./Pages/YourCv/components/Resume";
import PdfComponent from "./Pages/YourCv/components/PdfComponent";
import ExpiredLogout from "./Components/ExpiredLogout";
import CompetencePaths from "./Pages/CompetencePaths";
import SingleBadge from "./Pages/SingleBadge";
import SingleCompetencePath from "./Pages/SingleCompetencePath";
import CompetenceProfile from "./Pages/CompetenceProfile";
import CompetenceProfileSection from "./Pages/CompetenceProfileSection";
import Validator from "./Pages/Validator";
import SingleBadgeModel from "./Pages/SingleBadgeModel";
import { createContext, useMemo, useState } from "react";
import PublicBadge from "./Pages/PublicBadge";

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
  const { user: currentUser } = useSelector((state) => state.auth);

  const [mode, setMode] = useState("light");

  const theme = createTheme({
    palette: {
      mode,
      primary: {
        // main: "#d2ab67",
        main: "#294e6b", //Kolor do nagrywek
      },
      secondary: {
        main: "#404040",
      },
      fontWhite: {
        main: "#fff",
      },
      status: {
        main: "#a4ffaa",
      },
    },
  });

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/" element={<ProtectedRoute user={currentUser} />}> */}
            <Route element={<ProtectedRoute user={currentUser} />}>
              {currentUser && currentUser.user.userRole === "user" && (
                <>
                  {/* <Route path="/" element={<Certificates />} /> */}
                  <Route path="/" element={<Navigate to="/odznaki" />} />
                  <Route path="/odznaki/:badgeId" element={<SingleBadge />} />
                </>
              )}
              {currentUser && currentUser.user.userRole === "guest" && (
                <>
                  <Route path="/" element={<AboutUs />} />
                </>
              )}
              <Route path="/certificates" element={<Certificates />} />
              <Route
                path="/certificates/:certificateId"
                element={<SingleCertificate />}
              />
              <Route path="/qualifications" element={<Qualifications />} />
              <Route path="/odznaki" element={<Badges />} />
              <Route path="/odznaki/:badgeId" element={<SingleBadge />} />
              <Route
                path="/odznaki-do-odblokowania/:badgeId"
                element={<SingleBadgeModel />}
              />
              <Route path="/sciezki" element={<CompetencePaths />} />
              <Route
                path="/sciezki/:pathId"
                element={<SingleCompetencePath />}
              />

              <Route path="/profil-kompetencyjny">
                <Route
                  path="/profil-kompetencyjny/:profileId"
                  element={<CompetenceProfile />}
                />
                <Route
                  path="/profil-kompetencyjny/:profileId/sekcja/:sectionName"
                  element={<CompetenceProfileSection />}
                />
              </Route>
              <Route path="/profile" element={<Profile />} />
              <Route path="/yourcv">
                <Route path="/yourcv/" element={<Resume />} exact></Route>
                <Route
                  path="/yourcv/preview"
                  element={<PdfComponent />}
                ></Route>
              </Route>
            </Route>
            {!currentUser && (
              <>
                <Route path="/" element={<Navigate to="/login" />} />
                <Route path="login" element={<Login />} />
                <Route path="register" element={<RegisterGuest />} />
                <Route path="register">
                  <Route path=":userId">
                    <Route path=":courseId">
                      <Route path=":firstname">
                        <Route path=":lastname">
                          <Route path=":email" element={<Register />}></Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
                <Route
                  path="terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />}>
                  <Route path=":code" element={<ResetPassword />} />
                </Route>
                <Route path="confirmed" element={<EmailConfirmation />} />
              </>
            )}
            <Route path="logout" element={<ExpiredLogout />} />
            <Route path="notconfirmed" element={<EmailNotConfirmed />} />
            <Route path="validator" element={<Validator />} />
            <Route path="public-badge/:slug" element={<PublicBadge />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
        <Copyright style={{ marginBottom: "25px" }} />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
