import { ResponsiveBar } from "@nivo/bar";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCompetenceProfileStats } from "../../Slices/competenceProfiles";

const matrix = [
  {
    section: "qualifications",
    translation: "Kwalifikacje",
  },
  {
    section: "languages",
    translation: "Języki obce",
  },
  {
    section: "itCompetences",
    translation: "Kompetencje IT",
  },
  {
    section: "socialCompetences",
    translation: "Kompetencje społeczne",
  },
  {
    section: "economicActivity",
    translation: "Działalność gospodarcza",
  },
];

export const ProfileSectionsStatsBar = () => {
  const dispatch = useDispatch();
  let { profileId } = useParams();

  const profileStats = useSelector(
    (state) => state.competenceProfile.profileStats
  );

  const data = Object.entries(profileStats).map(([key, data]) => {
    return {
      section: matrix.find((item) => item.section === key).translation,
      "Mój wynik": data.user,
      "Mój wynikColor": "#55E098",
      "Wynik innych": data.usersAverage,
      "Wynik innychColor": "#404040",
    };
  });

  useEffect(() => {
    dispatch(getCompetenceProfileStats(profileId));
  }, [profileId]);

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <ResponsiveBar
        data={data.reverse()}
        keys={["Mój wynik", "Wynik innych"]}
        indexBy="section"
        margin={{ top: 50, right: 40, bottom: 80, left: 160 }}
        padding={0.3}
        groupMode="grouped"
        layout="horizontal"
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        colors={(bar) => bar.data[`${bar.id}Color`]}
        fill={[
          {
            match: {
              id: "Mój wynik",
            },
            id: "dots",
          },
          {
            match: {
              id: "Wynik innychColor",
            },
            id: "lines",
          },
        ]}
        borderColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: "color",
          modifiers: [["darker", 1.6]],
        }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: -160,
            translateY: 80,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Wykres sekcji"
        barAriaLabel={(e) =>
          e.id + ": " + e.formattedValue + " in section: " + e.indexValue
        }
      />
    </div>
  );
};
