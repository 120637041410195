import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../Actions/services";
import { setMessage } from "./message";

const badge = JSON.parse(sessionStorage.getItem("badges"));

export const getBadges = createAsyncThunk(
  "badges",
  async ({ email, user }, thunkAPI) => {
    try {
      if (email && user) {
        const data = await userService.getUserBadges(email, user);

        return { badges: data.data.data };
      }
    } catch (error) {
      console.log(error);
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = badge ? badge : [];

const getBadgesSlice = createSlice({
  name: "badges",
  initialState,
  extraReducers: {
    [getBadges.pending]: (state, action) => {
      return state;
    },
    [getBadges.fulfilled]: (state, action) => {
      return (state = action.payload.badges);
    },
    [getBadges.rejected]: (state, action) => {
      return (state = []);
    },
  },
});
const { reducer } = getBadgesSlice;
export default reducer;

// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import userService from "../Actions/services";
// import { setMessage } from "./message";

// const badge = JSON.parse(sessionStorage.getItem("badges"));

// export const getBadges = createAsyncThunk(
//   "badges",
//   async ({ email, user }, thunkAPI) => {
//     try {
//       if (email && user) {
//         console.log(user.jwt);
//         const data = await userService.getUserBadges(email, user);
//         console.log("getBadges", data.data.data);

//         return { badges: data.data.data };
//       }
//     } catch (error) {
//       console.log(error);
//       return thunkAPI.rejectWithValue();
//     }
//   }
// );

// export const getBadgeModel = createAsyncThunk(
//   "badges",
//   async ({ id, user }, thunkAPI) => {
//     try {
//       if (id && user) {
//         console.log(user.jwt);
//         const data = await userService.getBadgeModel(id, user);
//         console.log("getBadgeModel", data.data.data);

//         return { badges: data.data.data };
//       }
//     } catch (error) {
//       console.log(error);
//       return thunkAPI.rejectWithValue();
//     }
//   }
// );

// // const initialState = badge ? badge : [];
// const initialState = {
//   userBadges: [],
//   badgeModel: [],
// };

// const getBadgesSlice = createSlice({
//   name: "badges",
//   initialState,
//   extraReducers: {
//     [getBadges.pending]: (state, action) => {
//       return state;
//     },
//     [getBadges.fulfilled]: (state, action) => {
//       return (state = { ...state, userBadges: action.payload.badges });
//     },
//     [getBadges.rejected]: (state, action) => {
//       console.log(state, action);
//       return (state = []);
//     },
//     // [getBadgeModel.pending]: (state, action) => {
//     //   return state;
//     // },
//     // [getBadgeModel.fulfilled]: (state, action) => {
//     //   return (state = { ...state, badgeModel: action.payload });
//     // },
//     // [getBadgeModel.rejected]: (state, action) => {
//     //   console.log(state, action);
//     //   return (state = []);
//     // },
//   },
// });
// const { reducer } = getBadgesSlice;
// export default reducer;
