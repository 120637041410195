import React from "react";
import { ResponsivePie } from "@nivo/pie";

const ProfileScoreRadialChart = ({ profileScore }) => {
  const CenteredMetric = ({ centerX, centerY, dataWithArc }) => {
    const metricValue = profileScore?.userPercentage
      ? `${Math.floor(profileScore?.userPercentage)}%`
      : "0%";
    const metricLabelTop = "Jesteś lepszy od";
    const metricLabelBottom = "uczestników";

    return (
      <g transform={`translate(${centerX},${centerY})`} textAnchor="middle">
        <text fontSize={9} fill="#d2ab67" dy="-1.1em">
          {metricLabelTop}
        </text>
        <text fontSize={18} fill="#d2ab67" dy="0.5em">
          {metricValue}
        </text>
        <text fontSize={9} fill="#d2ab67" dy="2.1em">
          {metricLabelBottom}
        </text>
      </g>
    );
  };

  return (
    <ResponsivePie
      data={[
        {
          id: "Profile poniżej twojego rozwoju ścieżki (%)",
          label: `PRK${profileScore?.userPercentage}`,
          value: profileScore?.userPercentage,
          color: "hsl(25, 70%, 50%)",
        },
        {
          id: "Profile powyżej twojego rozwoju ścieżki (%)",
          label: `PRK${profileScore?.userPercentage}`,
          value: 100 - profileScore?.userPercentage,
          color: "hsl(25, 70%, 50%)",
        },
      ]}
      margin={{
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      }}
      innerRadius={0.8}
      padAngle={0.7}
      cornerRadius={0}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      colors={["#FFA726", "#ffffff"]}
      enableArcLinkLabels={false}
      layers={["arcs", "arcLinkLabels", CenteredMetric]}
    />
  );
};

export default ProfileScoreRadialChart;
