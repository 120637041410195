import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateUserResume } from "../Actions/authenticationActions";
import { setMessage } from "./message";

import userService from "../Actions/services";

export const updateResume = createAsyncThunk(
  "resume/update",
  async ({ id, token, payload }, thunkAPI) => {
    try {
      const cvData = {
        about: payload.about,
        skills: payload.skills,
        profile: payload.profile,
        isEdited: payload.isEdited,
        interests: payload.interests,
        educationList: payload.educationList,
        itCompetences: payload.itCompetences,
        experienceList: payload.experienceList,
        externalCourses: payload.externalCourses,
        foreignLanguages: payload.foreignLanguages,
        socialCompetences: payload.socialCompetences,
        businessActivities: payload.businessActivities,
      };
      const data = await updateUserResume(id, token, cvData);
      return data;
    } catch (error) {
      console.error(error.response.data.error);
      let message;
      if (error.response.data.error.status) {
        message = "Podałeś niepoprawne dane";
      }
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

// export const updateResume = createAsyncThunk(
//   "resume/update",
//   async ({ id, token, payload }, thunkAPI) => {
//     console.log(id, token, payload);
//     try {
//       console.log(id, token, payload);
//       const cvData = {
//         about: payload.about,
//         skills: payload.skills,
//         profile: payload.profile,
//         isEdited: payload.isEdited,
//         interests: payload.interests,
//         educationList: payload.educationList,
//         itCompetences: payload.itCompetences,
//         experienceList: payload.experienceList,
//         externalCourses: payload.externalCourses,
//         foreignLanguages: payload.foreignLanguages,
//         socialCompetences: payload.socialCompetences,
//         businessActivities: payload.businessActivities,
//       };
//       console.log(cvData);
//       // const data = await updateUserResume(id, token, payload);
//       const data = await updateUserResume(id, token, cvData);
//       // console.log(data);
//       return data;
//     } catch (error) {
//       console.error(error.response.data.error);
//       let message;
//       if (error.response.data.error.status) {
//         message = "Podałeś niepoprawne dane";
//       }
//       // const message =
//       //   (error.response &&
//       //     error.response.data &&
//       //     error.response.data.message) ||
//       //   error.message ||
//       //   error.toString();
//       // console.log(message)
//       thunkAPI.dispatch(setMessage(message));
//       return thunkAPI.rejectWithValue();
//     }
//   }
// );

export const getResume = createAsyncThunk(
  "resume/get",
  async ({ id, token }, thunkAPI) => {
    try {
      const data = await userService.getUserResume(id, token);
      if (data.status === 200) {
        return { resume: data };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  isEdited: false,
  skills: [],
  socialCompetences: [],
  itCompetences: [],
  file: "",
  profile: {
    name: "",
    location: "",
    facebook: "",
    linkedin: "",
    website: "",
    email: "",
    contact: "",
    position: "",
    tagline: "",
  },
  experienceList: [],
  educationList: [],
  externalCourses: [],
  foreignLanguages: [],
  businessActivities: [],
  interests: [],
};

const resumeSlice = createSlice({
  name: "resume",
  initialState,
  reducers: {
    manageAbout(state, action) {
      state.about = action.payload;
      state.isEdited = true;
    },
    addEducation(state, action) {
      state.educationList = [...state.educationList, action.payload];
      state.isEdited = true;
    },
    editEducation(state, action) {
      let newArr = [...state.educationList];
      newArr[action.payload.id] = action.payload;
      state.educationList = newArr;
      state.isEdited = true;
    },
    removeEducation(state, action) {
      let arr = [...state.educationList];
      arr.splice(action.payload, 1);
      state.educationList = arr;
      state.isEdited = true;
    },
    addExperience(state, action) {
      state.experienceList = [...state.experienceList, action.payload];
      state.isEdited = true;
    },
    editExperience(state, action) {
      let newArr = [...state.experienceList];
      newArr[action.payload.id] = action.payload;
      state.experienceList = newArr;
      state.isEdited = true;
    },
    removeExperience(state, action) {
      let arr = [...state.experienceList];
      arr.splice(action.payload, 1);
      state.experienceList = arr;
      state.isEdited = true;
    },
    manageFile(state, action) {
      state.file = action.payload;
      state.isEdited = true;
    },
    manageProfile(state, action) {
      state.profile = action.payload;
      state.isEdited = true;
    },
    addSkill(state, action) {
      state.skills = [...state.skills, action.payload];
      state.isEdited = true;
    },
    editSkill(state, action) {
      state.skills = action.payload;
      state.isEdited = true;
    },
    removeSkill(state, action) {
      let arr = [...state.skills];
      arr.splice(action.payload, 1);
      state.skills = arr;
      state.isEdited = true;
    },
    addInterest(state, action) {
      state.interests = [...state.interests, action.payload];
      state.isEdited = true;
    },
    editInterest(state, action) {
      state.interests = action.payload;
      state.isEdited = true;
    },
    removeInterest(state, action) {
      let arr = [...state.interests];
      arr.splice(action.payload, 1);
      state.interests = arr;
      state.isEdited = true;
    },
    addSocialCompetence(state, action) {
      state.socialCompetences = [...state.socialCompetences, action.payload];
      state.isEdited = true;
    },
    editSocialCompetence(state, action) {
      state.socialCompetences = action.payload;
      state.isEdited = true;
    },
    removeSocialCompetence(state, action) {
      let arr = [...state.socialCompetences];
      arr.splice(action.payload, 1);
      state.socialCompetences = arr;
      state.isEdited = true;
    },
    addItCompetence(state, action) {
      state.itCompetences = [...state.itCompetences, action.payload];
      state.isEdited = true;
    },
    editItCompetence(state, action) {
      state.itCompetences = action.payload;
      state.isEdited = true;
    },
    removeItCompetence(state, action) {
      let arr = [...state.itCompetences];
      arr.splice(action.payload, 1);
      state.itCompetences = arr;
      state.isEdited = true;
    },
    addBusinessActivity(state, action) {
      state.businessActivities = [...state.businessActivities, action.payload];
      state.isEdited = true;
    },
    editBusinessActivity(state, action) {
      state.businessActivities = action.payload;
      state.isEdited = true;
    },
    removeBusinessActivity(state, action) {
      let arr = [...state.businessActivities];
      arr.splice(action.payload, 1);
      state.businessActivities = arr;
      state.isEdited = true;
    },
    addExternalCourses(state, action) {
      state.externalCourses = [...state.externalCourses, action.payload];
      state.isEdited = true;
    },
    editExternalCourses(state, action) {
      let newArr = [...state.externalCourses];
      newArr[action.payload.id] = action.payload;
      state.externalCourses = newArr;
      state.isEdited = true;
    },
    removeExternalCourses(state, action) {
      let arr = [...state.externalCourses];
      arr.splice(action.payload, 1);
      state.externalCourses = arr;
      state.isEdited = true;
    },
    addForeignLanguages(state, action) {
      state.foreignLanguages = [...state.foreignLanguages, action.payload];
      state.isEdited = true;
    },
    editForeignLanguages(state, action) {
      let newArr = [...state.foreignLanguages];
      newArr[action.payload.id] = action.payload;
      state.foreignLanguages = newArr;
      state.isEdited = true;
    },
    removeForeignLanguages(state, action) {
      let arr = [...state.foreignLanguages];
      arr.splice(action.payload, 1);
      state.foreignLanguages = arr;
      state.isEdited = true;
    },
  },
  extraReducers: {
    [updateResume.fulfilled]: (state, action) => {
      state.isEdited = false;
      state = action.payload;
    },
    [updateResume.rejected]: (state, action) => {
      state.isEdited = false;
      return state;
    },
    [getResume.fulfilled]: (state, action) => {
      state.isEdited = false;
      return { ...state, ...action.payload.resume.data.data.attributes };
      // return (state = action.payload.resume.data.resume);
    },
    [getResume.rejected]: (state, action) => {
      return state;
    },
  },
});

const { actions, reducer } = resumeSlice;
export const {
  manageAbout,
  addEducation,
  editEducation,
  removeEducation,
  addExperience,
  editExperience,
  removeExperience,
  manageFile,
  manageProfile,
  addSkill,
  removeSkill,
  editSkill,
  addInterest,
  editInterest,
  removeInterest,
  addSocialCompetence,
  removeSocialCompetence,
  editSocialCompetence,
  addExternalCourses,
  editExternalCourses,
  removeExternalCourses,
  addForeignLanguages,
  editForeignLanguages,
  removeForeignLanguages,
  addBusinessActivity,
  editBusinessActivity,
  removeBusinessActivity,
  addItCompetence,
  editItCompetence,
  removeItCompetence,
} = actions;
export default reducer;
