import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Button,
  FormHelperText,
  ListItemText,
  ListItem,
  List,
  Container,
} from "@mui/material";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import CreateIcon from "@mui/icons-material/Create";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  addExperience,
  editExperience,
  removeExperience,
} from "../../../Slices/resume";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pl";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import userService from "../../../Actions/services";
import UploadButton from "../../../Components/UploadButton";

function Experience() {
  const experienceList = useSelector((state) => state.resume.experienceList);
  const { jwt, user } = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [disableIsWorking, setDisableIsWorking] = useState(false);
  const [progress, setProgress] = useState();
  const [attachments, setAttachments] = useState([]);

  const [experienceData, setExperienceData] = useState({
    job: "",
    company: "",
    isWorking: false,
    startDate: "",
    endDate: "",
    location: "",
    description: "",
    isEdit: false,
  });

  const ExperienceSchema = yup.object().shape({
    job: yup
      .string()
      .required("Stanowisko jest wymagane")
      .min(3, "Pole stanowisko jest za krótkie"),
    company: yup
      .string()
      .required("Pole firma jest wymagane")
      .min(3, "Pole firma jest za krótkie"),
    startDate: yup
      .string()
      .required("Data rozpoczęcia jest wymagana")
      .default(experienceData.startDate),
    endDate: yup
      .string()
      .required("Data zakończenia jest wymagana")
      .default(experienceData.endDate),
    location: yup
      .string()
      .required("Lokalizacja jest wymagana")
      .min(3, "Pole lokalizacja jest za krótkie"),
    description: yup
      .string()
      .required("Opis jest wymagany")
      .min(3, "Opis jest za krótki"),
  });

  const form = useForm({
    mode: "onChange",
    resolver: yupResolver(ExperienceSchema),
  });

  const dayAfter = dayjs(form.watch("startDate")).add(1, "day");

  const handleClose = () => {
    setShow(false);
    setExperienceData({
      id: "",
      job: "",
      company: "",
      isWorking: false,
      startDate: "",
      endDate: "",
      location: "",
      description: "",
      isEdit: false,
    });
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    userService.getResumeAttachedFiles(163, jwt);
    // .then((res) => console.log(res.data.data.attributes));
  }, [show]);

  const onSubmit = (data) => {
    if (experienceData.isEdit) {
      dispatch(editExperience({ ...experienceData, ...data }));
    } else {
      dispatch(addExperience(data));
    }
    setShow(false);
    setExperienceData({
      id: "",
      job: "",
      company: "",
      isWorking: false,
      startDate: "",
      endDate: "",
      location: "",
      description: "",
      isEdit: false,
    });
  };

  const onUpload = () => {
    userService.uploadFile(
      jwt,
      user,
      form.watch("file"),
      "filesExperience",
      setProgress
    );
  };

  const handleEdit = (id) => {
    const form = experienceList[id];
    setExperienceData({ ...form, isEdit: true, id: id });
    setShow(true);
  };

  const handleDelete = (id) => {
    dispatch(removeExperience(id));
  };

  const handleIsWorking = (e) => {
    // form.setValue("startDate", dayjs());
    form.setValue("endDate", dayjs());
    setDisableIsWorking(e.target.checked);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    paddingTop: "15px",
    paddingBottom: "15px",
    textAlign: "left",
    color: theme.palette.text.secondary,
  }));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const onError = (error) => console.log(error);

  return (
    <>
      <Item>
        <Grid container>
          <Grid sx={{ alignSelf: "center", flexGrow: 1 }}>
            <Typography variant="h6">Doświadczenie zawodowe</Typography>
          </Grid>
          <Grid justifyContent="flex-end">
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={handleShow}
            >
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
      </Item>

      <Grid container>
        <Grid item xs={12} sx={{ alignSelf: "center", flexGrow: 1 }}>
          {experienceList?.map((item, id) => {
            return (
              <Paper
                elevation={0}
                key={id}
                sx={{
                  p: 2,
                  bgcolor: "background.default",
                  // height: "100px",
                  borderBottom: "1px solid #555",
                  borderRadius: 0,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={2} sm={1} sx={{ alignSelf: "center" }}>
                    <WorkOutlineIcon
                      fontSize="large"
                      sx={{ color: "#d2ab67" }}
                    />
                  </Grid>
                  <Grid item xs={8} sm={9} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6">{item.title}</Typography>
                    <Typography
                      variant="h7"
                      display="block"
                      gutterBottom
                      sx={{ mb: 0 }}
                    >
                      {item.company} •{" "}
                      {dayjs(item.startDate).format("DD.MM.YYYY")}
                      {`${
                        item.isWorking
                          ? " - Obecnie"
                          : " - " + dayjs(item.endDate).format("DD.MM.YYYY")
                      }`}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ mb: 0 }}
                    >
                      {item.location}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ mb: 0 }}
                    >
                      {item.description}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    sx={{
                      display: "flex",
                      flexFlow: "column-reverse",
                      alignItems: "end",
                      alignSelf: "center",
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => {
                        handleDelete(id);
                      }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="create"
                      size="small"
                      onClick={() => {
                        handleEdit(id);
                      }}
                    >
                      <CreateIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            );
          })}
        </Grid>
      </Grid>

      <Modal open={show} onClose={handleClose} sx={{ overflow: "scroll" }}>
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Doświadczenie zawodowe
          </Typography>
          <FormProvider {...form}>
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { my: 1, width: "100%" },
              }}
              noValidate
              autoComplete="off"
              onSubmit={form.handleSubmit(onSubmit, onError)}
            >
              <Grid container spacing={0}>
                <Controller
                  name="job"
                  defaultValue={experienceData.job}
                  rules={{ required: true }}
                  shouldUnregister="true"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Stanowisko"
                      placeholder="np. Kucharz"
                      value={value}
                      onChange={onChange}
                      helperText={error && error.message}
                      error={error !== undefined}
                      sx={{ width: "100%" }}
                    />
                  )}
                />

                <Controller
                  name="company"
                  defaultValue={experienceData.company}
                  rules={{ required: true }}
                  shouldUnregister="true"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      label="Nazwa firmy"
                      placeholder="np. Restauracja Roma"
                      value={value}
                      onChange={onChange}
                      helperText={error && error.message}
                      error={error !== undefined}
                      sx={{ width: "100%" }}
                    />
                  )}
                />

                <Controller
                  name="isWorking"
                  defaultValue={experienceData.isWorking}
                  rules={{ required: true }}
                  shouldUnregister="true"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <>
                      <FormControlLabel
                        control={<Checkbox checked={form.isWorking} />}
                        name="isWorking"
                        value={value}
                        label="Aktualnie pracuję na tym stanowisku"
                        onChange={(e) => {
                          onChange(e);
                          handleIsWorking(e);
                        }}
                      />
                    </>
                  )}
                />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="caption">Data rozpoczęcia</Typography>
                    <Controller
                      name="startDate"
                      defaultValue={experienceData.startDate}
                      rules={{ required: true }}
                      shouldUnregister="true"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pl"
                        >
                          <DatePicker
                            label="Data od"
                            value={dayjs(value)}
                            onChange={onChange}
                            slotProps={{
                              textField: {
                                helperText: error && error.message,
                              },
                            }}
                            // error={error !== undefined}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="caption">Data zakończenia</Typography>

                    <Controller
                      name="endDate"
                      defaultValue={experienceData.endDate}
                      rules={{ required: true }}
                      shouldUnregister="true"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale="pl"
                        >
                          <DatePicker
                            label="Data do"
                            value={dayjs(value)}
                            onChange={onChange}
                            minDate={dayAfter}
                            slotProps={{
                              textField: {
                                helperText: error && error.message,
                              },
                            }}
                            disabled={disableIsWorking}
                            // error={error !== undefined}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Controller
                name="location"
                defaultValue={experienceData.location}
                rules={{ required: true }}
                shouldUnregister="true"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Lokalizacja"
                    placeholder="np. Lublin, Polska"
                    value={value}
                    onChange={onChange}
                    helperText={error && error.message}
                    error={error !== undefined}
                    sx={{ width: "100%", mt: "15px" }}
                  />
                )}
              />

              <Controller
                name="description"
                defaultValue={experienceData.description}
                rules={{ required: true }}
                shouldUnregister="true"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <TextField
                    label="Opis"
                    placeholder="np. Pracuje jako przedstawiciel handlowy"
                    value={value}
                    onChange={onChange}
                    helperText={error && error.message}
                    error={error !== undefined}
                    sx={{ width: "100%", mt: "15px" }}
                  />
                )}
              />

              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "12px",
                  }}
                >
                  <Controller
                    name="file"
                    control={form.control}
                    rules={{ required: true }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <UploadButton
                          onChange={(e) => onChange(e.target.files)}
                          error={error && error.message}
                          value={value}
                          progress={progress}
                          onUpload={onUpload}
                          // setEducationData={setEducationData}
                          // educationData={educationData}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{
                    textAlign: "center",
                    marginTop: "10px",
                    marginBottom: "12px",
                  }}
                >
                  <Typography variant="caption">
                    Pliki w sekcji edukacja:
                  </Typography>
                  <Container maxWidth={false} disableGutters>
                    <List
                      // maxWidth={false}
                      // disableGutters
                      sx={{
                        width: "100%",
                        maxWidth: 345,
                        bgcolor: "background.paper",
                      }}
                    >
                      {attachments.data?.map((val) => (
                        <ListItem
                          key={val.id}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() =>
                                userService.removeResumeAttachedFile(
                                  val.id,
                                  jwt
                                )
                              }
                            >
                              <DeleteOutlineIcon />
                            </IconButton>
                          }
                          sx={{ borderBottom: "1px solid #e0e0e0" }}
                        >
                          <ListItemText primary={val.attributes.name} />
                        </ListItem>
                      ))}
                    </List>
                  </Container>
                </Grid>
              </Grid>

              <Button variant="outlined" type="submit" sx={{ mt: "10px" }}>
                Zapisz zmiany
              </Button>
            </Box>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}

export default Experience;
