import React, { useEffect, useState } from "react";
import { useDocumentTitle } from "../../Hooks/useDocumentTitle";
import { Footer } from "../../Components/Footer";
import {
  Box,
  Container,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import userService from "../../Actions/services";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function SingleBadgeModel() {
  useDocumentTitle("Odznaka niewystawiona");
  let { badgeId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const [badge, setBadge] = useState();

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    userService
      .getBadgeModel({ id: badgeId, token: user.jwt })
      .then((data) => setBadge(data.data.data.attributes));
  }, [badgeId, user.jwt]);

  //   const selectBadge = createSelector(
  //     (state) => state.badges,
  //     (badges) => badges.filter((badge) => badge.id === Number(badgeId))[0]
  //   );
  //   const badge = useSelector(selectBadge);

  return (
    <>
      {badge && (
        <Container sx={{ opacity: 0.6 }}>
          <div
            id="watermark"
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              opacity: 0.2,
              zIndex: "-200",
              backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='300px' width='300px'><text transform='translate(33, 282) rotate(-45)' fill='rgb(210,171,103,1)' font-size='30'>Odznaka nieodblokowana</text></svg>")`,
            }}
          />
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h4"
                  align="center"
                  color="text.primary"
                  sx={{ marginY: "40px" }}
                >
                  {badge.badgeName}
                </Typography>
              </Grid>

              <Grid container spacing={8}>
                <Grid item xs={5} sx={{ textAlign: "center" }}>
                  <img
                    // src={`${process.env.REACT_APP_STRAPI_MEDIA}${badge.attributes.image.data.attributes.url}`}
                    src={badge.image.data?.attributes.url}
                    alt="badge"
                    style={{
                      width: "90%",
                      marginTop: "20px",
                      filter: "grayscale(1)",
                      opacity: 0.4,
                    }}
                  />
                  <Grid container sx={{ marginTop: "50px" }}>
                    {/* {badge.sets.data.map((set) => (
                      <Grid item xs={6} sx={{ textAlign: "center" }}>
                        <Typography
                          component="h4"
                          variant="caption"
                          align="center"
                          color="text.primary"
                        >
                          {set.badgeName}
                        </Typography>
                        <img
                          // src={`${process.env.REACT_APP_MEDIA_URL}${set.attributes.image?.data.attributes.url}`}
                          src={set.image.data.url}
                          alt="badge"
                          style={{ width: "60%", marginTop: "20px" }}
                        />
                      </Grid>
                    ))} */}
                  </Grid>
                </Grid>
                <Grid item xs={7}>
                  <Typography
                    component="h2"
                    variant="h5"
                    sx={{ marginBottom: "50px" }}
                  >
                    <span style={{ fontWeight: "bold" }}>{badge.desc}</span>
                  </Typography>
                  <Box>
                    <Typography component="h4" variant="subtitle1">
                      Data wystawienia:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.issuanceDate}
                      </span>
                    </Typography>
                    <Typography component="h4" variant="subtitle1">
                      Instytucja wystawiająca: {<b>Fundacja VCC</b>}
                      <span style={{ fontWeight: "bold" }}>{badge.issuer}</span>
                    </Typography>
                    <Typography component="h4" variant="subtitle1">
                      Rodzaj odznaki:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.badgeType}
                      </span>
                    </Typography>
                    <Typography component="h4" variant="subtitle1">
                      Kwalifikacja:{" "}
                      <span style={{ fontWeight: "bold" }}>
                        {badge.qualificationName}
                      </span>
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: "40px" }}>
                    <Typography component="h4" variant="subtitle1">
                      EFEKTY UCZENIA SIĘ:
                      <List>
                        {badge.learningEffects?.learningEffects?.map((item) => (
                          <ListItem sx={{ padding: 0 }}>
                            <ListItemIcon>
                              <ChevronRightIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.effectName} />
                          </ListItem>
                        ))}
                      </List>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <Footer />
    </>
  );
}
