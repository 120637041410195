import { combineReducers, configureStore } from "@reduxjs/toolkit";
import auth from "./Slices/auth";
import messageReducer from "./Slices/message";
import certificatesReducer from "./Slices/certificates";
import qualificationsReducer from "./Slices/qualifications";
import badgesReducer from "./Slices/badges";
import resumeSlice from "./Slices/resume";
import competencePathsReducer from "./Slices/competencePaths";
import competencePathReducer from "./Slices/competencePath";
import competenceProfileReducer from "./Slices/competenceProfiles";

const reducer = combineReducers({
  auth: auth,
  message: messageReducer,
  certificates: certificatesReducer,
  badges: badgesReducer,
  qualifications: qualificationsReducer,
  resume: resumeSlice,
  competencePahts: competencePathsReducer,
  singlePath: competencePathReducer,
  competenceProfile: competenceProfileReducer,
});

const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
