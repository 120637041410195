import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SectionPaper = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  display: "flex",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "flex-start",
  color: theme.palette.text.secondary,
  lineHeight: "60px",
  borderRadius: "16px",
  height: "100%",
}));
