import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../Actions/services";
import badgeService from "../Actions/openBadgeServices";
import { setMessage } from "./message";
const user = JSON.parse(localStorage.getItem("user"));
const badge = JSON.parse(sessionStorage.getItem("badges"));

export const getPathStats = createAsyncThunk("pathStats", async (thunkAPI) => {
  try {
    const data = await badgeService.getPathStats();
    return { pathStats: data };
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
});

export const getMostCommonQualifications = createAsyncThunk(
  "mostCommonQualifications",
  async (thunkAPI) => {
    try {
      const data = await badgeService.getMostCommonQualifications();
      return { mostCommonQualifications: data };
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getPrkStats = createAsyncThunk(
  "prkStats",
  async (token, thunkAPI) => {
    try {
      if (token) {
        const data = await badgeService.getPrkStats();
        return { prkStats: data };
      }
    } catch (error) {
      console.log("getPrkStats error", error);
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getCompetencePaths = createAsyncThunk(
  "competencePaths",
  async ({ token, page }, thunkAPI) => {
    try {
      if (token) {
        const data = await userService.getCompetencePaths(token, page);
        return {
          competencePaths: data.data.data,
          pagination: data.data.meta.pagination,
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  pathStats: {
    certificates: "",
    badges: "",
  },
  mostCommonQualifications: [],
  prkStats: [],
  competencePaths: [],
};

const competencePathSlice = createSlice({
  name: "competencePaths",
  initialState,
  extraReducers: {
    [getPathStats.pending]: (state, action) => {
      return state;
    },
    [getPathStats.fulfilled]: (state, action) => {
      return (state = { ...state, ...action.payload });
    },
    [getPathStats.rejected]: (state, action) => {
      return (state = []);
    },
    [getMostCommonQualifications.pending]: (state, action) => {
      return state;
    },
    [getMostCommonQualifications.fulfilled]: (state, action) => {
      return (state = { ...state, ...action.payload });
    },
    [getMostCommonQualifications.rejected]: (state, action) => {
      return (state = []);
    },
    [getPrkStats.pending]: (state, action) => {
      return state;
    },
    [getPrkStats.fulfilled]: (state, action) => {
      return (state = { ...state, ...action.payload });
    },
    [getPrkStats.rejected]: (state, action) => {
      return (state = []);
    },
    [getCompetencePaths.pending]: (state, action) => {
      return state;
    },
    [getCompetencePaths.fulfilled]: (state, action) => {
      return (state = { ...state, ...action.payload });
    },
    [getCompetencePaths.rejected]: (state, action) => {
      return (state = []);
    },
  },
});
const { reducer } = competencePathSlice;
export default reducer;
