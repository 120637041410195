import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import badgeService from "../Actions/openBadgeServices";
import { useParams } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  // p: 4,
};

const matrix = [
  {
    type: "JWT_SIGNATURE_VERIFIED",
    translation: "Podpis cyfrowy został zweryfikowany prawidłowo",
  },
  {
    type: "JWT_ISS_MATCHES_VC_ISSUER_ID",
    translation: "Podpis cyfrowy jest zgodny z identyfikatorem wydawcy",
  },
  {
    type: "JWT_SUB_MATCHES_VC_SUBJECT_ID",
    translation: "Podpis cyfrowy jest zgodny z identyfikatorem poświadczenia",
  },
  {
    type: "JWT_NBF_EQUALS_VC_ISSUANCE_DATE",
    translation: "Zweryfikowano prawidłowo datę wydania poświadczenia",
  },
  {
    type: "JWT_JTI_MATCHES_VC_ID",
    translation: "Identyfikator poświadczenia jest unikalny",
  },
  {
    type: "JWT_EXP_EQUALS_VC_EXPIRATION_DATE",
    translation: "Zweryfikowano prawidłowo datę wygaśnięcia poświadczenia",
  },
  {
    type: "VC_VALID",
    translation: "Data wydania poświadczenia zweryfikowana prawidłowo",
  },
  {
    type: "VC_NOT_EXPIRED",
    translation: "Poświadczenie jest ważne",
  },
];

const ValidateBadgeFake = React.forwardRef(({ setShow }, ref) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [data, setData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [finalJudgement, setFinalJudgement] = useState(false);

  const handleClick = () => {
    setData(matrix);
    setFinalJudgement(true);
    setValidated(true);
  };

  useEffect(() => {
    const delay = Math.floor(Math.random() * (600 - 100 + 1)) + 100;
    if (currentIndex < data.length - 1) {
      const timer = setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [currentIndex, data]);

  return (
    <Grid tabIndex={-1} container sx={style} ref={ref}>
      <Grid item md={12}>
        <Paper sx={{ textAlign: "center" }}>
          <List>
            {data.map((item, index) => (
              <ListItem
                key={index}
                sx={{
                  display: index <= currentIndex ? "flex" : "none",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
              >
                <ListItemIcon>
                  <CheckCircleOutlineIcon sx={{ color: "green" }} />
                </ListItemIcon>
                <ListItemText
                  primary={item.translation}
                  // sx={{ fontSize: 2, color: "green" }}
                  // primary={item.type}
                  // secondary={item.description}
                />
              </ListItem>
            ))}
          </List>

          {validated ? (
            <Button
              variant="contained"
              onClick={() => setShow(false)}
              sx={{
                width: "200px",
                margin: "30px",
                padding: "10px",
              }}
            >
              ZAMKNIJ
            </Button>
          ) : (
            <>
              <Typography>
                Klikinj sprawdź aby zweryfikować autentyczność
                Mikropoświadczenia
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleClick()}
                sx={{
                  width: "200px",
                  margin: "30px",
                  padding: "10px",
                }}
              >
                SPRAWDŹ
              </Button>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
});

export default ValidateBadgeFake;
